import * as React from 'react';

import { PickerStyleTag } from './Stylesheet/stylesheet';
import { Reactions } from './components/Reactions/Reactions';
import { Body } from './components/body/Body';
import { ElementRefContextProvider } from './components/context/ElementRefContext';
import { PickerConfigProvider } from './components/context/PickerConfigContext';
import { useReactionsModeState } from './components/context/PickerContext';
import { Preview } from './components/footer/Preview';
import { Header } from './components/header/Header';
import PickerMain from './components/main/PickerMain';
import { compareConfig } from './config/compareConfig';
import { useAllowExpandReactions, useOpenConfig } from './config/useConfig';

import { PickerProps } from './index';

function EmojiPicker(props: PickerProps) {
  return (
    <elementrefcontextprovider>
      <pickerstyletag></pickerstyletag>
      <pickerconfigprovider {...props}="">
        <contentcontrol></contentcontrol>
      </pickerconfigprovider>
    </elementrefcontextprovider>
  );
}

function ContentControl() {
  const [reactionsDefaultOpen] = useReactionsModeState();
  const allowExpandReactions = useAllowExpandReactions();

  const [renderAll, setRenderAll] = React.useState(!reactionsDefaultOpen);
  const isOpen = useOpenConfig();

  React.useEffect(() => {
    if (reactionsDefaultOpen && !allowExpandReactions) {
      return;
    }

    if (!renderAll) {
      setRenderAll(true);
    }
  }, [renderAll, allowExpandReactions, reactionsDefaultOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <pickermain>
      <reactions></reactions>
      <expandedpickercontent renderAll="{renderAll}"></expandedpickercontent>
    </pickermain>
  );
}

function ExpandedPickerContent({ renderAll }: { renderAll: boolean }) {
  if (!renderAll) {
    return null;
  }

  return (
    <>
      <header></header>
      <body></body>
      <preview></preview>
    </>
  );
}

// eslint-disable-next-line complexity
export default React.memo(EmojiPicker, compareConfig);
