import type { StoreEnhancer } from 'redux';
import type { AutoBatchOptions } from './autoBatchEnhancer';
import { autoBatchEnhancer } from './autoBatchEnhancer';
import { Tuple } from './utils';
import type { Middlewares } from './configureStore';
import type { ExtractDispatchExtensions } from './tsHelpers';
type GetDefaultEnhancersOptions = {
  autoBatch?: boolean | AutoBatchOptions;
};
export type GetDefaultEnhancers<m extends="" Middlewares<any="">> = (options?: GetDefaultEnhancersOptions) => Tuple<[StoreEnhancer<{
  dispatch: ExtractDispatchExtensions<m>;
}>]>;
export const buildGetDefaultEnhancers = <m extends="" Middlewares<any="">,>(middlewareEnhancer: StoreEnhancer<{
  dispatch: ExtractDispatchExtensions<m>;
}>): GetDefaultEnhancers<m> => function getDefaultEnhancers(options) {
  const {
    autoBatch = true
  } = options ?? {};
  let enhancerArray = new Tuple<storeenhancer[]>(middlewareEnhancer);
  if (autoBatch) {
    enhancerArray.push(autoBatchEnhancer(typeof autoBatch === 'object' ? autoBatch : undefined));
  }
  return (enhancerArray as any);
};</storeenhancer[]></m></m></m></m></m>