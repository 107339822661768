import * as React from 'react';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import InputAdornment, { InputAdornmentProps } from '@material-ui/core/InputAdornment';
import TextField, { BaseTextFieldProps, TextFieldProps } from '@material-ui/core/TextField';
import { Rifm } from 'rifm';
import { ExtendMui } from '../typings/extendMui';
import { KeyboardIcon } from './icons/KeyboardIcon';
import { makeMaskFromFormat, maskedDateFormatter } from '../_helpers/text-field-helper';

export interface KeyboardDateInputProps
  extends ExtendMui<basetextfieldprops, 'variant'="" |="" 'onError'="" 'value'=""> {
  format: string;
  onChange: (value: string | null) => void;
  openPicker: () => void;
  validationError?: React.ReactNode;
  inputValue: string;
  inputProps?: TextFieldProps['inputProps'];
  InputProps?: TextFieldProps['InputProps'];
  onBlur?: TextFieldProps['onBlur'];
  onFocus?: TextFieldProps['onFocus'];
  /** Override input component */
  TextFieldComponent?: React.ComponentType<textfieldprops>;
  /** Icon displaying for open picker button */
  keyboardIcon?: React.ReactNode;
  /** Pass material-ui text field variant down, bypass internal variant prop */
  inputVariant?: TextFieldProps['variant'];
  /**
   * Custom mask. Can be used to override generate from format. (e.g. __/__/____ __:__)
   */
  mask?: string;
  /**
   * Char string that will be replaced with number (for "_" mask will be "__/__/____")
   * @default '_'
   */
  maskChar?: string;
  /**
   * Refuse values regexp
   * @default /[^\d]+/gi
   */
  refuse?: RegExp;
  /**
   * Props to pass to keyboard input adornment
   * @type {Partial<inputadornmentprops>}
   */
  InputAdornmentProps?: Partial<inputadornmentprops>;
  /**
   * Props to pass to keyboard adornment button
   * @type {Partial<iconbuttonprops>}
   */
  KeyboardButtonProps?: Partial<iconbuttonprops>;
  /** Custom formatter to be passed into Rifm component */
  rifmFormatter?: (str: string) => string;
}

export const KeyboardDateInput: React.FunctionComponent<keyboarddateinputprops> = ({
  inputValue,
  inputVariant,
  validationError,
  KeyboardButtonProps,
  InputAdornmentProps,
  openPicker: onOpen,
  onChange,
  InputProps,
  mask,
  maskChar = '_',
  refuse = /[^\d]+/gi,
  format,
  keyboardIcon,
  disabled,
  rifmFormatter,
  TextFieldComponent = TextField,
  ...other
}) => {
  const inputMask = mask || makeMaskFromFormat(format, maskChar);
  // prettier-ignore
  const formatter = React.useMemo(
    () => maskedDateFormatter(inputMask, maskChar, refuse),
    [inputMask, maskChar, refuse]
  );

  const position =
    InputAdornmentProps && InputAdornmentProps.position ? InputAdornmentProps.position : 'end';

  const handleChange = (text: string) => {
    const finalString = text === '' || text === inputMask ? null : text;
    onChange(finalString);
  };

  return (
    <rifm key="{inputMask}" value="{inputValue}" onChange="{handleChange}" refuse="{refuse}" format="{rifmFormatter" ||="" formatter}="">
      {({ onChange, value }) => (
        <textfieldcomponent disabled="{disabled}" error="{Boolean(validationError)}" helperText="{validationError}" {...other}="" value="{value}" onChange="{onChange}" variant="{inputVariant" as="" any}="" InputProps="{{" ...InputProps,="" [`${position}Adornment`]:="" (="" <InputAdornment="" position="{position}" {...InputAdornmentProps}="">
                <iconbutton disabled="{disabled}" {...KeyboardButtonProps}="" onClick="{onOpen}">
                  {keyboardIcon}
                </iconbutton>
              
            ),
          }}
        />
      )}
    </textfieldcomponent></rifm>
  );
};

KeyboardDateInput.defaultProps = {
  keyboardIcon: <keyboardicon></keyboardicon>,
};

export default KeyboardDateInput;
</keyboarddateinputprops></iconbuttonprops></iconbuttonprops></inputadornmentprops></inputadornmentprops></textfieldprops></basetextfieldprops,>