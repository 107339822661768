import * as React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { DIALOG_WIDTH, DIALOG_WIDTH_WIDER } from '../constants/dimensions';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

export interface ModalDialogProps extends DialogProps {
  onAccept: () => void;
  onDismiss: () => void;
  onClear: () => void;
  onSetToday: () => void;
  okLabel?: React.ReactNode;
  cancelLabel?: React.ReactNode;
  clearLabel?: React.ReactNode;
  todayLabel?: React.ReactNode;
  clearable?: boolean;
  showTodayButton?: boolean;
  showTabs?: boolean;
  wider?: boolean;
}

export const ModalDialog: React.SFC<modaldialogprops &="" WithStyles<typeof="" styles="">> = ({
  children,
  classes,
  onAccept,
  onDismiss,
  onClear,
  onSetToday,
  okLabel,
  cancelLabel,
  clearLabel,
  todayLabel,
  clearable,
  showTodayButton,
  showTabs,
  wider,
  ...other
}) => (
  <dialog role="dialog" onClose="{onDismiss}" classes="{{" paper:="" clsx(classes.dialogRoot,="" {="" [classes.dialogRootWider]:="" wider,="" }),="" }}="" {...other}="">
    <dialogcontent children="{children}" className="{classes.dialog}"></dialogcontent>

    <dialogactions classes="{{" root:="" clsx({="" [classes.withAdditionalAction]:="" clearable="" ||="" showTodayButton,="" }),="" }}="">
      {clearable && (
        <button color="primary" onClick="{onClear}">
          {clearLabel}
        </button>
      )}

      {showTodayButton && (
        <button color="primary" onClick="{onSetToday}">
          {todayLabel}
        </button>
      )}

      {cancelLabel && (
        <button color="primary" onClick="{onDismiss}">
          {cancelLabel}
        </button>
      )}

      {okLabel && (
        <button color="primary" onClick="{onAccept}">
          {okLabel}
        </button>
      )}
    </dialogactions>
  </dialog>
);

ModalDialog.displayName = 'ModalDialog';

export const styles = createStyles({
  dialogRoot: {
    minWidth: DIALOG_WIDTH,
  },
  dialogRootWider: {
    minWidth: DIALOG_WIDTH_WIDER,
  },
  dialog: {
    '&:first-child': {
      padding: 0,
    },
  },
  withAdditionalAction: {
    // set justifyContent to default value to fix IE11 layout bug
    // see https://github.com/dmtrKovalenko/material-ui-pickers/pull/267
    justifyContent: 'flex-start',

    '& > *:first-child': {
      marginRight: 'auto',
    },
  },
});

export default withStyles(styles, { name: 'MuiPickersModal' })(ModalDialog);
</modaldialogprops>