export type Id<t> = { [K in keyof T]: T[K] } & {};
export type WithRequiredProp<t, K="" extends="" keyof="" T=""> = Omit<t, K=""> & Required<pick<t, K="">>;
export type Override<t1, T2=""> = T2 extends any ? Omit<t1, keyof="" T2=""> & T2 : never;
export function assertCast<t>(v: any): asserts v is T {}
export function safeAssign<t extends="" object="">(target: T, ...args: Array<partial<noinfer<t>>>): T {
  return Object.assign(target, ...args);
}

/**
 * Convert a Union type `(A|B)` to an intersection type `(A&B)`
 */
export type UnionToIntersection<u> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;
export type NonOptionalKeys<t> = { [K in keyof T]-?: undefined extends T[K] ? never : K }[keyof T];
export type HasRequiredProps<t, True,="" False=""> = NonOptionalKeys<t> extends never ? False : True;
export type OptionalIfAllPropsOptional<t> = HasRequiredProps<t, T,="" T="" |="" never="">;
export type NoInfer<t> = [T][T extends any ? 0 : never];
export type NonUndefined<t> = T extends undefined ? never : T;
export type UnwrapPromise<t> = T extends PromiseLike<infer V=""> ? V : T;
export type MaybePromise<t> = T | PromiseLike<t>;
export type OmitFromUnion<t, K="" extends="" keyof="" T=""> = T extends any ? Omit<t, K=""> : never;
export type IsAny<t, True,="" False="never"> = true | false extends (T extends never ? true : false) ? True : False;
export type CastAny<t, CastTo=""> = IsAny<t, CastTo,="" T="">;</t,></t,></t,></t,></t,></t></t></infer></t></t></t></t,></t></t></t,></t></u></partial<noinfer<t></t></t></t1,></t1,></pick<t,></t,></t,></t>