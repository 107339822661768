import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ArrowLeftIcon: React.SFC<svgiconprops> = props => {
  return (
    <svgicon {...props}="">
      <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
      <path fill="none" d="M0 0h24v24H0V0z"></path>
    </svgicon>
  );
};
</svgiconprops>