import * as React from 'react';
import * as PropTypes from 'prop-types';
import Popover, { PopoverProps as PopoverPropsType } from '@material-ui/core/Popover';
import { WrapperProps } from './Wrapper';
import { useKeyDown } from '../_shared/hooks/useKeyDown';
import { TextFieldProps } from '@material-ui/core/TextField';

export interface InlineWrapperProps<t =="" TextFieldProps=""> extends WrapperProps<t> {
  /** Popover props passed to material-ui Popover (with variant="inline") */
  PopoverProps?: Partial<popoverpropstype>;
}

export const InlineWrapper: React.FC<inlinewrapperprops> = ({
  open,
  wider,
  children,
  PopoverProps,
  onClear,
  onDismiss,
  onSetToday,
  onAccept,
  showTabs,
  DateInputProps,
  InputComponent,
  ...other
}) => {
  const ref = React.useRef();

  useKeyDown(open, {
    Enter: onAccept,
  });

  return (
    <react.fragment>
      <inputcomponent {...other}="" {...DateInputProps}="" inputRef="{ref}"></inputcomponent>

      <popover open="{open}" onClose="{onDismiss}" anchorEl="{ref.current}" anchorOrigin="{{" vertical:="" 'bottom',="" horizontal:="" 'center',="" }}="" transformOrigin="{{" 'top',="" children="{children}" {...PopoverProps}=""></popover>
    </react.fragment>
  );
};

InlineWrapper.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  PopoverProps: PropTypes.object,
} as any;
</inlinewrapperprops></popoverpropstype></t></t>