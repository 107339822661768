<div>// @flow

import { SC_ATTR, SC_ATTR_ACTIVE, SC_ATTR_VERSION, SC_VERSION } from '../constants';
import getNonce from '../utils/nonce';
import throwStyledError from '../utils/error';

const ELEMENT_TYPE = 1; /* Node.ELEMENT_TYPE */

/** Find last style element if any inside target */
const findLastStyleTag = (target: HTMLElement): void | HTMLStyleElement => {
  const { childNodes } = target;

  for (let i = childNodes.length; i >= 0; i--) {
    const child = ((childNodes[i]: any): ?HTMLElement);
    if (child && child.nodeType === ELEMENT_TYPE && child.hasAttribute(SC_ATTR)) {
      return ((child: any): HTMLStyleElement);
    }
  }

  return undefined;
};

/** Create a style element inside `target` or <head> after the last */
export const makeStyleTag = (target?: HTMLElement): HTMLStyleElement => {
  const head = ((document.head: any): HTMLElement);
  const parent = target || head;
  const style = document.createElement('style');
  const prevStyle = findLastStyleTag(parent);
  const nextSibling = prevStyle !== undefined ? prevStyle.nextSibling : null;

  style.setAttribute(SC_ATTR, SC_ATTR_ACTIVE);
  style.setAttribute(SC_ATTR_VERSION, SC_VERSION);

  const nonce = getNonce();

  if (nonce) style.setAttribute('nonce', nonce);

  parent.insertBefore(style, nextSibling);

  return style;
};

/** Get the CSSStyleSheet instance for a given style element */
export const getSheet = (tag: HTMLStyleElement): CSSStyleSheet => {
  if (tag.sheet) {
    return ((tag.sheet: any): CSSStyleSheet);
  }

  // Avoid Firefox quirk where the style element might not have a sheet property
  const { styleSheets } = document;
  for (let i = 0, l = styleSheets.length; i < l; i++) {
    const sheet = styleSheets[i];
    if (sheet.ownerNode === tag) {
      return ((sheet: any): CSSStyleSheet);
    }
  }

  throwStyledError(17);
  return (undefined: any);
};
<script type="application/json" id="weglot-data">{"originalCanonicalUrl":"https://app-test.firetiger.fr/static/js/16.a6a0d79a.chunk.js.map","allLanguageUrls":{"fr":"https://app-test.firetiger.fr/static/js/16.a6a0d79a.chunk.js.map","en":"https://en.app-test.firetiger.fr/static/js/16.a6a0d79a.chunk.js.map"},"originalPath":"/static/js/16.a6a0d79a.chunk.js.map","settings":{"website":"http://app-test.firetiger.fr","uid":"afd8604e52","project_slug":"firetiger","deleted_at":null,"api_key_translate":"wg_6cac2f56e719206bd430f11bf697ec908","language_from":"fr","language_from_custom_flag":null,"language_from_custom_name":null,"auto_switch":false,"auto_switch_fallback":null,"translation_engine":3,"excluded_paths":[],"excluded_blocks":[],"dynamics":[{"value":".MuiContainer-root"}],"custom_settings":{"button_style":{"with_name":true,"full_name":true,"is_dropdown":true,"with_flags":false,"flag_type":"rectangle_mat","custom_css":""},"switchers":[],"translate_search":false,"loading_bar":true,"hide_switcher":false,"translate_images":false,"subdomain":false,"wait_transition":true,"dynamic":""},"media_enabled":false,"external_enabled":false,"page_views_enabled":false,"versions":[],"pending_translation_enabled":false,"curl_ssl_check_enabled":true,"languages":[{"language_to":"en","custom_code":null,"custom_name":null,"custom_local_name":null,"provider":null,"enabled":true,"automatic_translation_enabled":true,"deleted_at":null,"connect_host_destination":{"is_dns_set":true,"created_on_aws":1645703315,"host":"en.app-test.firetiger.fr"},"custom_flag":null}],"category":1,"organization_slug":"w-1353ad9665","technology_id":12,"technology_name":"Other","host":"app-test.firetiger.fr","is_https":true,"url_type":"SUBDOMAIN","is_dns_set":false,"main_record":null}}</script>
<script type="text/javascript" src="https://cdn.weglot.com/weglot.min.js?api_key=wg_910fc61b6baaa769d992653b778d58818&hide_switcher=true"></script><link href="https://app-test.firetiger.fr/static/js/16.a6a0d79a.chunk.js.map" hreflang="fr" rel="alternate"><link href="https://en.app-test.firetiger.fr/static/js/16.a6a0d79a.chunk.js.map" hreflang="en" rel="alternate"></head></div>// @flow

import { SC_ATTR, SC_ATTR_ACTIVE, SC_ATTR_VERSION, SC_VERSION } from '../constants';
import getNonce from '../utils/nonce';
import throwStyledError from '../utils/error';

const ELEMENT_TYPE = 1; /* Node.ELEMENT_TYPE */

/** Find last style element if any inside target */
const findLastStyleTag = (target: HTMLElement): void | HTMLStyleElement => {
  const { childNodes } = target;

  for (let i = childNodes.length; i >= 0; i--) {
    const child = ((childNodes[i]: any): ?HTMLElement);
    if (child && child.nodeType === ELEMENT_TYPE && child.hasAttribute(SC_ATTR)) {
      return ((child: any): HTMLStyleElement);
    }
  }

  return undefined;
};

/** Create a style element inside `target` or <head> after the last */
export const makeStyleTag = (target?: HTMLElement): HTMLStyleElement => {
  const head = ((document.head: any): HTMLElement);
  const parent = target || head;
  const style = document.createElement('style');
  const prevStyle = findLastStyleTag(parent);
  const nextSibling = prevStyle !== undefined ? prevStyle.nextSibling : null;

  style.setAttribute(SC_ATTR, SC_ATTR_ACTIVE);
  style.setAttribute(SC_ATTR_VERSION, SC_VERSION);

  const nonce = getNonce();

  if (nonce) style.setAttribute('nonce', nonce);

  parent.insertBefore(style, nextSibling);

  return style;
};

/** Get the CSSStyleSheet instance for a given style element */
export const getSheet = (tag: HTMLStyleElement): CSSStyleSheet => {
  if (tag.sheet) {
    return ((tag.sheet: any): CSSStyleSheet);
  }

  // Avoid Firefox quirk where the style element might not have a sheet property
  const { styleSheets } = document;
  for (let i = 0, l = styleSheets.length; i < l; i++) {
    const sheet = styleSheets[i];
    if (sheet.ownerNode === tag) {
      return ((sheet: any): CSSStyleSheet);
    }
  }

  throwStyledError(17);
  return (undefined: any);
};
<script type="application/json" id="weglot-data">{"originalCanonicalUrl":"https://app-test.firetiger.fr/static/js/16.a6a0d79a.chunk.js.map","allLanguageUrls":{"fr":"https://app-test.firetiger.fr/static/js/16.a6a0d79a.chunk.js.map","en":"https://en.app-test.firetiger.fr/static/js/16.a6a0d79a.chunk.js.map"},"originalPath":"/static/js/16.a6a0d79a.chunk.js.map","settings":{"website":"http://app-test.firetiger.fr","uid":"afd8604e52","project_slug":"firetiger","deleted_at":null,"api_key_translate":"wg_6cac2f56e719206bd430f11bf697ec908","language_from":"fr","language_from_custom_flag":null,"language_from_custom_name":null,"auto_switch":false,"auto_switch_fallback":null,"translation_engine":3,"excluded_paths":[],"excluded_blocks":[],"dynamics":[{"value":".MuiContainer-root"}],"custom_settings":{"button_style":{"with_name":true,"full_name":true,"is_dropdown":true,"with_flags":false,"flag_type":"rectangle_mat","custom_css":""},"switchers":[],"translate_search":false,"loading_bar":true,"hide_switcher":false,"translate_images":false,"subdomain":false,"wait_transition":true,"dynamic":""},"media_enabled":false,"external_enabled":false,"page_views_enabled":false,"versions":[],"pending_translation_enabled":false,"curl_ssl_check_enabled":true,"languages":[{"language_to":"en","custom_code":null,"custom_name":null,"custom_local_name":null,"provider":null,"enabled":true,"automatic_translation_enabled":true,"deleted_at":null,"connect_host_destination":{"is_dns_set":true,"created_on_aws":1645703315,"host":"en.app-test.firetiger.fr"},"custom_flag":null}],"category":1,"organization_slug":"w-1353ad9665","technology_id":12,"technology_name":"Other","host":"app-test.firetiger.fr","is_https":true,"url_type":"SUBDOMAIN","is_dns_set":false,"main_record":null}}</script>
<script type="text/javascript" src="https://cdn.weglot.com/weglot.min.js?api_key=wg_910fc61b6baaa769d992653b778d58818&hide_switcher=true"></script><link href="https://app-test.firetiger.fr/static/js/16.a6a0d79a.chunk.js.map" hreflang="fr" rel="alternate"><link href="https://en.app-test.firetiger.fr/static/js/16.a6a0d79a.chunk.js.map" hreflang="en" rel="alternate"></head> after the last */
export const makeStyleTag = (target?: HTMLElement): HTMLStyleElement => {
  const head = ((document.head: any): HTMLElement);
  const parent = target || head;
  const style = document.createElement('style');
  const prevStyle = findLastStyleTag(parent);
  const nextSibling = prevStyle !== undefined ? prevStyle.nextSibling : null;

  style.setAttribute(SC_ATTR, SC_ATTR_ACTIVE);
  style.setAttribute(SC_ATTR_VERSION, SC_VERSION);

  const nonce = getNonce();

  if (nonce) style.setAttribute('nonce', nonce);

  parent.insertBefore(style, nextSibling);

  return style;
};

/** Get the CSSStyleSheet instance for a given style element */
export const getSheet = (tag: HTMLStyleElement): CSSStyleSheet => {
  if (tag.sheet) {
    return ((tag.sheet: any): CSSStyleSheet);
  }

  // Avoid Firefox quirk where the style element might not have a sheet property
  const { styleSheets } = document;
  for (let i = 0, l = styleSheets.length; i < l; i++) {
    const sheet = styleSheets[i];
    if (sheet.ownerNode === tag) {
      return ((sheet: any): CSSStyleSheet);
    }
  }

  throwStyledError(17);
  return (undefined: any);
};
