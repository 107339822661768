import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const KeyboardIcon: React.SFC<svgiconprops> = props => {
  return (
    <svgicon {...props}="">
      <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
      <path fill="none" d="M0 0h24v24H0z"></path>
    </svgicon>
  );
};
</svgiconprops>