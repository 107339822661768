import React from 'react';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flex: '1 0 auto',
    },
    searchIcon: {
      color: theme.palette.text.secondary,
      marginTop: '10px',
      marginRight: '8px',
    },
    searchText: {
      flex: '0.8 0',
    },
    clearIcon: {
      '&:hover': {
        color: theme.palette.error.main,
      },
    },
  }),
  { name: 'MUIDataTableSearch' },
);

const TableSearch = ({ options, searchText, onSearch, onHide }) => {
  const classes = useStyles();

  const handleTextChange = event => {
    onSearch(event.target.value);
  };

  const onKeyDown = event => {
    if (event.key === 'Escape') {
      onHide();
    }
  };

  const clearIconVisibility = options.searchAlwaysOpen ? 'hidden' : 'visible';

  return (
    <grow appear="" in="{true}" timeout="{300}">
      <div className="{classes.main}">
        <searchicon className="{classes.searchIcon}"></searchicon>
        <textfield className="{classes.searchText}" autoFocus="{true}" InputProps="{{" 'data-test-id':="" options.textLabels.toolbar.search,="" }}="" inputProps="{{" 'aria-label':="" value="{searchText" ||="" ''}="" onKeyDown="{onKeyDown}" onChange="{handleTextChange}" fullWidth="{true}" placeholder="{options.searchPlaceholder}" {...(options.searchProps="" ?="" options.searchProps="" :="" {})}=""></textfield>
        <iconbutton className="{classes.clearIcon}" style="{{" visibility:="" clearIconVisibility="" }}="" onClick="{onHide}">
          <clearicon></clearicon>
        </iconbutton>
      </div>
    </grow>
  );
};

export default TableSearch;
