/** Use it instead of .includes method for IE support */
export function arrayIncludes<t>(array: T[], itemOrItems: T | T[]) {
  if (Array.isArray(itemOrItems)) {
    return itemOrItems.every(item => array.indexOf(item) !== -1);
  }

  return array.indexOf(itemOrItems) !== -1;
}

export type Omit<t, K="" extends="" keyof="" any=""> = Pick<t, Exclude<keyof="" T,="" K="">>;
</t,></t,></t>