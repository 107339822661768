import * as React from 'react';
import { Omit } from '../_helpers/utils';
import { useUtils } from './hooks/useUtils';
import { IUtils } from '@date-io/core/IUtils';
import { MaterialUiPickersDate } from '../typings/date';

export interface WithUtilsProps {
  utils: IUtils<materialuipickersdate>;
}

export const withUtils = () => <p extends="" WithUtilsProps="">(Component: React.ComponentType</p><p>) => {
  const WithUtils: React.SFC<omit<p, keyof="" WithUtilsProps="">> = props => {
    const utils = useUtils();
    return <component utils="{utils}" {...(props="" as="" any)}=""></component>;
  };

  WithUtils.displayName = `WithUtils(${Component.displayName || Component.name})`;
  return WithUtils;
};
</omit<p,></p></materialuipickersdate>