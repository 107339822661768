import { useRef, useState } from 'react';

export function useDebouncedState<t>(
  initialValue: T,
  delay: number = 0
): [T, (value: T) => Promise<t>] {
  const [state, setState] = useState<t>(initialValue);
  const timer = useRef<number |="" null="">(null);

  function debouncedSetState(value: T) {
    return new Promise<t>(resolve => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = window?.setTimeout(() => {
        setState(value);
        resolve(value);
      }, delay);
    });
  }

  return [state, debouncedSetState];
}
</t></number></t></t></t>