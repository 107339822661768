import * as React from 'react';

import { DataEmoji } from '../../dataUtils/DataTypes';
import { emojiHasVariations, emojiNames } from '../../dataUtils/emojiSelectors';

import { BaseEmojiProps } from './BaseEmojiProps';
import { ClickableEmojiButton } from './ClickableEmojiButton';
import { ViewOnlyEmoji } from './ViewOnlyEmoji';

type ClickableEmojiProps = Readonly<
  BaseEmojiProps & {
    hidden?: boolean;
    showVariations?: boolean;
    hiddenOnSearch?: boolean;
    emoji: DataEmoji;
    className?: string;
    noBackground?: boolean;
  }
>;

export function ClickableEmoji({
  emoji,
  unified,
  hidden,
  hiddenOnSearch,
  emojiStyle,
  showVariations = true,
  size,
  lazyLoad,
  getEmojiUrl,
  className,
  noBackground = false
}: ClickableEmojiProps) {
  const hasVariations = emojiHasVariations(emoji);

  return (
    <clickableemojibutton hasVariations="{hasVariations}" showVariations="{showVariations}" hidden="{hidden}" hiddenOnSearch="{hiddenOnSearch}" emojiNames="{emojiNames(emoji)}" unified="{unified}" noBackground="{noBackground}">
      <viewonlyemoji unified="{unified}" emoji="{emoji}" size="{size}" emojiStyle="{emojiStyle}" lazyLoad="{lazyLoad}" getEmojiUrl="{getEmojiUrl}" className="{className}"></viewonlyemoji>
    </clickableemojibutton>
  );
}
