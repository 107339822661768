import type { Dispatch, UnknownAction } from 'redux';
import type { PayloadAction, ActionCreatorWithPreparedPayload } from './createAction';
import { createAction } from './createAction';
import type { ThunkDispatch } from 'redux-thunk';
import type { ActionFromMatcher, FallbackIfUnknown, Id, IsAny, IsUnknown, SafePromise, TypeGuard } from './tsHelpers';
import { nanoid } from './nanoid';
import { isAnyOf } from './matchers';

// @ts-ignore we need the import of these types due to a bundling issue.
type _Keep = PayloadAction | ActionCreatorWithPreparedPayload<any, unknown="">;
export type BaseThunkAPI<s, E,="" D="" extends="" Dispatch="Dispatch," RejectedValue="unknown," RejectedMeta="unknown," FulfilledMeta="unknown"> = {
  dispatch: D;
  getState: () => S;
  extra: E;
  requestId: string;
  signal: AbortSignal;
  abort: (reason?: string) => void;
  rejectWithValue: IsUnknown<rejectedmeta, (value:="" RejectedValue)=""> RejectWithValue<rejectedvalue, RejectedMeta="">, (value: RejectedValue, meta: RejectedMeta) => RejectWithValue<rejectedvalue, RejectedMeta="">>;
  fulfillWithValue: IsUnknown<fulfilledmeta, <FulfilledValue="">(value: FulfilledValue) => FulfilledValue, <fulfilledvalue>(value: FulfilledValue, meta: FulfilledMeta) => FulfillWithMeta<fulfilledvalue, FulfilledMeta="">>;
};

/**
 * @public
 */
export interface SerializedError {
  name?: string;
  message?: string;
  stack?: string;
  code?: string;
}
const commonProperties: Array<keyof SerializedError=""> = ['name', 'message', 'stack', 'code'];
class RejectWithValue<payload, RejectedMeta=""> {
  /*
  type-only property to distinguish between RejectWithValue and FulfillWithMeta
  does not exist at runtime
  */
  private readonly _type!: 'RejectWithValue';
  constructor(public readonly payload: Payload, public readonly meta: RejectedMeta) {}
}
class FulfillWithMeta<payload, FulfilledMeta=""> {
  /*
  type-only property to distinguish between RejectWithValue and FulfillWithMeta
  does not exist at runtime
  */
  private readonly _type!: 'FulfillWithMeta';
  constructor(public readonly payload: Payload, public readonly meta: FulfilledMeta) {}
}

/**
 * Serializes an error into a plain object.
 * Reworked from https://github.com/sindresorhus/serialize-error
 *
 * @public
 */
export const miniSerializeError = (value: any): SerializedError => {
  if (typeof value === 'object' && value !== null) {
    const simpleError: SerializedError = {};
    for (const property of commonProperties) {
      if (typeof value[property] === 'string') {
        simpleError[property] = value[property];
      }
    }
    return simpleError;
  }
  return {
    message: String(value)
  };
};
export type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: ThunkDispatch<unknown, unknown,="" UnknownAction="">;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};
type GetState<thunkapiconfig> = ThunkApiConfig extends {
  state: infer State;
} ? State : unknown;
type GetExtra<thunkapiconfig> = ThunkApiConfig extends {
  extra: infer Extra;
} ? Extra : unknown;
type GetDispatch<thunkapiconfig> = ThunkApiConfig extends {
  dispatch: infer Dispatch;
} ? FallbackIfUnknown<dispatch, ThunkDispatch<GetState<ThunkApiConfig="">, GetExtra<thunkapiconfig>, UnknownAction>> : ThunkDispatch<getstate<thunkapiconfig>, GetExtra<thunkapiconfig>, UnknownAction>;
export type GetThunkAPI<thunkapiconfig> = BaseThunkAPI<getstate<thunkapiconfig>, GetExtra<thunkapiconfig>, GetDispatch<thunkapiconfig>, GetRejectValue<thunkapiconfig>, GetRejectedMeta<thunkapiconfig>, GetFulfilledMeta<thunkapiconfig>>;
type GetRejectValue<thunkapiconfig> = ThunkApiConfig extends {
  rejectValue: infer RejectValue;
} ? RejectValue : unknown;
type GetPendingMeta<thunkapiconfig> = ThunkApiConfig extends {
  pendingMeta: infer PendingMeta;
} ? PendingMeta : unknown;
type GetFulfilledMeta<thunkapiconfig> = ThunkApiConfig extends {
  fulfilledMeta: infer FulfilledMeta;
} ? FulfilledMeta : unknown;
type GetRejectedMeta<thunkapiconfig> = ThunkApiConfig extends {
  rejectedMeta: infer RejectedMeta;
} ? RejectedMeta : unknown;
type GetSerializedErrorType<thunkapiconfig> = ThunkApiConfig extends {
  serializedErrorType: infer GetSerializedErrorType;
} ? GetSerializedErrorType : SerializedError;
type MaybePromise<t> = T | Promise<t> | (T extends any ? Promise<t> : never);

/**
 * A type describing the return value of the `payloadCreator` argument to `createAsyncThunk`.
 * Might be useful for wrapping `createAsyncThunk` in custom abstractions.
 *
 * @public
 */
export type AsyncThunkPayloadCreatorReturnValue<returned, ThunkApiConfig="" extends="" AsyncThunkConfig=""> = MaybePromise<isunknown<getfulfilledmeta<thunkapiconfig>, Returned, FulfillWithMeta<returned, GetFulfilledMeta<ThunkApiConfig="">>> | RejectWithValue<getrejectvalue<thunkapiconfig>, GetRejectedMeta<thunkapiconfig>>>;
/**
 * A type describing the `payloadCreator` argument to `createAsyncThunk`.
 * Might be useful for wrapping `createAsyncThunk` in custom abstractions.
 *
 * @public
 */
export type AsyncThunkPayloadCreator<returned, ThunkArg="void," ThunkApiConfig="" extends="" AsyncThunkConfig="{}"> = (arg: ThunkArg, thunkAPI: GetThunkAPI<thunkapiconfig>) => AsyncThunkPayloadCreatorReturnValue<returned, ThunkApiConfig="">;

/**
 * A ThunkAction created by `createAsyncThunk`.
 * Dispatching it returns a Promise for either a
 * fulfilled or rejected action.
 * Also, the returned value contains an `abort()` method
 * that allows the asyncAction to be cancelled from the outside.
 *
 * @public
 */
export type AsyncThunkAction<returned, ThunkArg,="" ThunkApiConfig="" extends="" AsyncThunkConfig=""> = (dispatch: NonNullable<getdispatch<thunkapiconfig>>, getState: () => GetState<thunkapiconfig>, extra: GetExtra<thunkapiconfig>) => SafePromise<returntype<asyncthunkfulfilledactioncreator<returned, ThunkArg="">> | ReturnType<asyncthunkrejectedactioncreator<thunkarg, ThunkApiConfig="">>> & {
  abort: (reason?: string) => void;
  requestId: string;
  arg: ThunkArg;
  unwrap: () => Promise<returned>;
};
type AsyncThunkActionCreator<returned, ThunkArg,="" ThunkApiConfig="" extends="" AsyncThunkConfig=""> = IsAny<thunkarg, any="" handling="" (arg:="" ThunkArg)=""> AsyncThunkAction<returned, ThunkArg,="" ThunkApiConfig="">,
// unknown handling
unknown extends ThunkArg ? (arg: ThunkArg) => AsyncThunkAction<returned, ThunkArg,="" ThunkApiConfig=""> /* argument not specified or specified as void or undefined*/ : [ThunkArg] extends [void] | [undefined] ? () => AsyncThunkAction<returned, ThunkArg,="" ThunkApiConfig=""> /* argument contains void*/ : [void] extends [ThunkArg] // make optional
? (arg?: ThunkArg) => AsyncThunkAction<returned, ThunkArg,="" ThunkApiConfig=""> /* argument contains undefined*/ : [undefined] extends [ThunkArg] ? WithStrictNullChecks<
// with strict nullChecks: make optional
(arg?: ThunkArg) => AsyncThunkAction<returned, ThunkArg,="" ThunkApiConfig="">,
// without strict null checks this will match everything, so don't make it optional
(arg: ThunkArg) => AsyncThunkAction<returned, ThunkArg,="" ThunkApiConfig="">> /* default case: normal argument*/ : (arg: ThunkArg) => AsyncThunkAction<returned, ThunkArg,="" ThunkApiConfig="">>;

/**
 * Options object for `createAsyncThunk`.
 *
 * @public
 */
export type AsyncThunkOptions<thunkarg =="" void,="" ThunkApiConfig="" extends="" AsyncThunkConfig="{}"> = {
  /**
   * A method to control whether the asyncThunk should be executed. Has access to the
   * `arg`, `api.getState()` and `api.extra` arguments.
   *
   * @returns `false` if it should be skipped
   */
  condition?(arg: ThunkArg, api: Pick<getthunkapi<thunkapiconfig>, 'getState' | 'extra'>): MaybePromise<boolean |="" undefined="">;
  /**
   * If `condition` returns `false`, the asyncThunk will be skipped.
   * This option allows you to control whether a `rejected` action with `meta.condition == false`
   * will be dispatched or not.
   *
   * @default `false`
   */
  dispatchConditionRejection?: boolean;
  serializeError?: (x: unknown) => GetSerializedErrorType<thunkapiconfig>;

  /**
   * A function to use when generating the `requestId` for the request sequence.
   *
   * @default `nanoid`
   */
  idGenerator?: (arg: ThunkArg) => string;
} & IsUnknown<getpendingmeta<thunkapiconfig>, {
  /**
   * A method to generate additional properties to be added to `meta` of the pending action.
   *
   * Using this optional overload will not modify the types correctly, this overload is only in place to support JavaScript users.
   * Please use the `ThunkApiConfig` parameter `pendingMeta` to get access to a correctly typed overload
   */
  getPendingMeta?(base: {
    arg: ThunkArg;
    requestId: string;
  }, api: Pick<getthunkapi<thunkapiconfig>, 'getState' | 'extra'>): GetPendingMeta<thunkapiconfig>;
}, {
  /**
   * A method to generate additional properties to be added to `meta` of the pending action.
   */
  getPendingMeta(base: {
    arg: ThunkArg;
    requestId: string;
  }, api: Pick<getthunkapi<thunkapiconfig>, 'getState' | 'extra'>): GetPendingMeta<thunkapiconfig>;
}>;
export type AsyncThunkPendingActionCreator<thunkarg, ThunkApiConfig="{}"> = ActionCreatorWithPreparedPayload<[string, ThunkArg, GetPendingMeta<thunkapiconfig>?], undefined, string, never, {
  arg: ThunkArg;
  requestId: string;
  requestStatus: 'pending';
} & GetPendingMeta<thunkapiconfig>>;
export type AsyncThunkRejectedActionCreator<thunkarg, ThunkApiConfig="{}"> = ActionCreatorWithPreparedPayload<[Error | null, string, ThunkArg, GetRejectValue<thunkapiconfig>?, GetRejectedMeta<thunkapiconfig>?], GetRejectValue<thunkapiconfig> | undefined, string, GetSerializedErrorType<thunkapiconfig>, {
  arg: ThunkArg;
  requestId: string;
  requestStatus: 'rejected';
  aborted: boolean;
  condition: boolean;
} & (({
  rejectedWithValue: false;
} & { [K in keyof GetRejectedMeta<thunkapiconfig>]?: undefined }) | ({
  rejectedWithValue: true;
} & GetRejectedMeta<thunkapiconfig>))>;
export type AsyncThunkFulfilledActionCreator<returned, ThunkArg,="" ThunkApiConfig="{}"> = ActionCreatorWithPreparedPayload<[Returned, string, ThunkArg, GetFulfilledMeta<thunkapiconfig>?], Returned, string, never, {
  arg: ThunkArg;
  requestId: string;
  requestStatus: 'fulfilled';
} & GetFulfilledMeta<thunkapiconfig>>;

/**
 * A type describing the return value of `createAsyncThunk`.
 * Might be useful for wrapping `createAsyncThunk` in custom abstractions.
 *
 * @public
 */
export type AsyncThunk<returned, ThunkArg,="" ThunkApiConfig="" extends="" AsyncThunkConfig=""> = AsyncThunkActionCreator<returned, ThunkArg,="" ThunkApiConfig=""> & {
  pending: AsyncThunkPendingActionCreator<thunkarg, ThunkApiConfig="">;
  rejected: AsyncThunkRejectedActionCreator<thunkarg, ThunkApiConfig="">;
  fulfilled: AsyncThunkFulfilledActionCreator<returned, ThunkArg,="" ThunkApiConfig="">;
  // matchSettled?
  settled: (action: any) => action is ReturnType<asyncthunkrejectedactioncreator<thunkarg, ThunkApiConfig=""> | AsyncThunkFulfilledActionCreator<returned, ThunkArg,="" ThunkApiConfig="">>;
  typePrefix: string;
};
export type OverrideThunkApiConfigs<oldconfig, NewConfig=""> = Id<newconfig &="" Omit<OldConfig,="" keyof="" NewConfig="">>;
type CreateAsyncThunk<curriedthunkapiconfig extends="" AsyncThunkConfig=""> = {
  /**
   *
   * @param typePrefix
   * @param payloadCreator
   * @param options
   *
   * @public
   */
  // separate signature without `AsyncThunkConfig` for better inference
  <returned, ThunkArg="void">(typePrefix: string, payloadCreator: AsyncThunkPayloadCreator<returned, ThunkArg,="" CurriedThunkApiConfig="">, options?: AsyncThunkOptions<thunkarg, CurriedThunkApiConfig="">): AsyncThunk<returned, ThunkArg,="" CurriedThunkApiConfig="">;

  /**
   *
   * @param typePrefix
   * @param payloadCreator
   * @param options
   *
   * @public
   */
  <returned, ThunkArg,="" ThunkApiConfig="" extends="" AsyncThunkConfig="">(typePrefix: string, payloadCreator: AsyncThunkPayloadCreator<returned, ThunkArg,="" OverrideThunkApiConfigs<CurriedThunkApiConfig,="" ThunkApiConfig="">>, options?: AsyncThunkOptions<thunkarg, OverrideThunkApiConfigs<CurriedThunkApiConfig,="" ThunkApiConfig="">>): AsyncThunk<returned, ThunkArg,="" OverrideThunkApiConfigs<CurriedThunkApiConfig,="" ThunkApiConfig="">>;
  withTypes<thunkapiconfig extends="" AsyncThunkConfig="">(): CreateAsyncThunk<overridethunkapiconfigs<curriedthunkapiconfig, ThunkApiConfig="">>;
};
export const createAsyncThunk = /* @__PURE__ */(() => {
  function createAsyncThunk<returned, ThunkArg,="" ThunkApiConfig="" extends="" AsyncThunkConfig="">(typePrefix: string, payloadCreator: AsyncThunkPayloadCreator<returned, ThunkArg,="" ThunkApiConfig="">, options?: AsyncThunkOptions<thunkarg, ThunkApiConfig="">): AsyncThunk<returned, ThunkArg,="" ThunkApiConfig=""> {
    type RejectedValue = GetRejectValue<thunkapiconfig>;
    type PendingMeta = GetPendingMeta<thunkapiconfig>;
    type FulfilledMeta = GetFulfilledMeta<thunkapiconfig>;
    type RejectedMeta = GetRejectedMeta<thunkapiconfig>;
    const fulfilled: AsyncThunkFulfilledActionCreator<returned, ThunkArg,="" ThunkApiConfig=""> = createAction(typePrefix + '/fulfilled', (payload: Returned, requestId: string, arg: ThunkArg, meta?: FulfilledMeta) => ({
      payload,
      meta: {
        ...((meta as any) || {}),
        arg,
        requestId,
        requestStatus: ('fulfilled' as const)
      }
    }));
    const pending: AsyncThunkPendingActionCreator<thunkarg, ThunkApiConfig=""> = createAction(typePrefix + '/pending', (requestId: string, arg: ThunkArg, meta?: PendingMeta) => ({
      payload: undefined,
      meta: {
        ...((meta as any) || {}),
        arg,
        requestId,
        requestStatus: ('pending' as const)
      }
    }));
    const rejected: AsyncThunkRejectedActionCreator<thunkarg, ThunkApiConfig=""> = createAction(typePrefix + '/rejected', (error: Error | null, requestId: string, arg: ThunkArg, payload?: RejectedValue, meta?: RejectedMeta) => ({
      payload,
      error: ((options && options.serializeError || miniSerializeError)(error || 'Rejected') as GetSerializedErrorType<thunkapiconfig>),
      meta: {
        ...((meta as any) || {}),
        arg,
        requestId,
        rejectedWithValue: !!payload,
        requestStatus: ('rejected' as const),
        aborted: error?.name === 'AbortError',
        condition: error?.name === 'ConditionError'
      }
    }));
    function actionCreator(arg: ThunkArg): AsyncThunkAction<returned, ThunkArg,="" Required<ThunkApiConfig="">> {
      return (dispatch, getState, extra) => {
        const requestId = options?.idGenerator ? options.idGenerator(arg) : nanoid();
        const abortController = new AbortController();
        let abortHandler: (() => void) | undefined;
        let abortReason: string | undefined;
        function abort(reason?: string) {
          abortReason = reason;
          abortController.abort();
        }
        const promise = async function () {
          let finalAction: ReturnType<typeof fulfilled="" |="" typeof="" rejected="">;
          try {
            let conditionResult = options?.condition?.(arg, {
              getState,
              extra
            });
            if (isThenable(conditionResult)) {
              conditionResult = await conditionResult;
            }
            if (conditionResult === false || abortController.signal.aborted) {
              // eslint-disable-next-line no-throw-literal
              throw {
                name: 'ConditionError',
                message: 'Aborted due to condition callback returning false.'
              };
            }
            const abortedPromise = new Promise<never>((_, reject) => {
              abortHandler = () => {
                reject({
                  name: 'AbortError',
                  message: abortReason || 'Aborted'
                });
              };
              abortController.signal.addEventListener('abort', abortHandler);
            });
            dispatch((pending(requestId, arg, options?.getPendingMeta?.({
              requestId,
              arg
            }, {
              getState,
              extra
            })) as any));
            finalAction = await Promise.race([abortedPromise, Promise.resolve(payloadCreator(arg, {
              dispatch,
              getState,
              extra,
              requestId,
              signal: abortController.signal,
              abort,
              rejectWithValue: (((value: RejectedValue, meta?: RejectedMeta) => {
                return new RejectWithValue(value, meta);
              }) as any),
              fulfillWithValue: (((value: unknown, meta?: FulfilledMeta) => {
                return new FulfillWithMeta(value, meta);
              }) as any)
            })).then(result => {
              if (result instanceof RejectWithValue) {
                throw result;
              }
              if (result instanceof FulfillWithMeta) {
                return fulfilled(result.payload, requestId, arg, result.meta);
              }
              return fulfilled((result as any), requestId, arg);
            })]);
          } catch (err) {
            finalAction = err instanceof RejectWithValue ? rejected(null, requestId, arg, err.payload, err.meta) : rejected((err as any), requestId, arg);
          } finally {
            if (abortHandler) {
              abortController.signal.removeEventListener('abort', abortHandler);
            }
          }
          // We dispatch the result action _after_ the catch, to avoid having any errors
          // here get swallowed by the try/catch block,
          // per https://twitter.com/dan_abramov/status/770914221638942720
          // and https://github.com/reduxjs/redux-toolkit/blob/e85eb17b39a2118d859f7b7746e0f3fee523e089/docs/tutorials/advanced-tutorial.md#async-error-handling-logic-in-thunks

          const skipDispatch = options && !options.dispatchConditionRejection && rejected.match(finalAction) && (finalAction as any).meta.condition;
          if (!skipDispatch) {
            dispatch((finalAction as any));
          }
          return finalAction;
        }();
        return Object.assign((promise as SafePromise<any>), {
          abort,
          requestId,
          arg,
          unwrap() {
            return promise.then<any>(unwrapResult);
          }
        });
      };
    }
    return Object.assign((actionCreator as AsyncThunkActionCreator<returned, ThunkArg,="" ThunkApiConfig="">), {
      pending,
      rejected,
      fulfilled,
      settled: isAnyOf(rejected, fulfilled),
      typePrefix
    });
  }
  createAsyncThunk.withTypes = () => createAsyncThunk;
  return (createAsyncThunk as CreateAsyncThunk<asyncthunkconfig>);
})();
interface UnwrappableAction {
  payload: any;
  meta?: any;
  error?: any;
}
type UnwrappedActionPayload<t extends="" UnwrappableAction=""> = Exclude<t, {="" error:="" any;="" }="">['payload'];

/**
 * @public
 */
export function unwrapResult<r extends="" UnwrappableAction="">(action: R): UnwrappedActionPayload<r> {
  if (action.meta && action.meta.rejectedWithValue) {
    throw action.payload;
  }
  if (action.error) {
    throw action.error;
  }
  return action.payload;
}
type WithStrictNullChecks<true, False=""> = undefined extends boolean ? False : True;
function isThenable(value: any): value is PromiseLike<any> {
  return value !== null && typeof value === 'object' && typeof value.then === 'function';
}</any></true,></r></r></t,></t></asyncthunkconfig></returned,></any></any></never></typeof></returned,></thunkapiconfig></thunkarg,></thunkarg,></returned,></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkapiconfig></returned,></thunkarg,></returned,></returned,></overridethunkapiconfigs<curriedthunkapiconfig,></thunkapiconfig></returned,></thunkarg,></returned,></returned,></returned,></thunkarg,></returned,></returned,></curriedthunkapiconfig></newconfig></oldconfig,></returned,></asyncthunkrejectedactioncreator<thunkarg,></returned,></thunkarg,></thunkarg,></returned,></returned,></thunkapiconfig></thunkapiconfig></returned,></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkarg,></thunkapiconfig></thunkapiconfig></thunkarg,></thunkapiconfig></getthunkapi<thunkapiconfig></thunkapiconfig></getthunkapi<thunkapiconfig></getpendingmeta<thunkapiconfig></thunkapiconfig></boolean></getthunkapi<thunkapiconfig></thunkarg></returned,></returned,></returned,></returned,></returned,></returned,></returned,></thunkarg,></returned,></returned></asyncthunkrejectedactioncreator<thunkarg,></returntype<asyncthunkfulfilledactioncreator<returned,></thunkapiconfig></thunkapiconfig></getdispatch<thunkapiconfig></returned,></returned,></thunkapiconfig></returned,></thunkapiconfig></getrejectvalue<thunkapiconfig></returned,></isunknown<getfulfilledmeta<thunkapiconfig></returned,></t></t></t></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkapiconfig></thunkapiconfig></getstate<thunkapiconfig></thunkapiconfig></thunkapiconfig></getstate<thunkapiconfig></thunkapiconfig></dispatch,></thunkapiconfig></thunkapiconfig></thunkapiconfig></unknown,></payload,></payload,></keyof></fulfilledvalue,></fulfilledvalue></fulfilledmeta,></rejectedvalue,></rejectedvalue,></rejectedmeta,></s,></any,>