import React, { PropsWithChildren, MutableRefObject } from 'react'
import { TooltipActionsContext, TooltipStateContext } from './context'
import { useTooltipHandlers } from './hooks'

interface TooltipProviderProps {
    container: MutableRefObject<htmldivelement>
}

export const TooltipProvider = ({
    container,
    children,
}: PropsWithChildren<tooltipproviderprops>) => {
    const { actions, state } = useTooltipHandlers(container)

    return (
        <tooltipactionscontext.provider value="{actions}">
            <tooltipstatecontext.provider value="{state}">{children}</tooltipstatecontext.provider>
        </tooltipactionscontext.provider>
    )
}
</tooltipproviderprops></htmldivelement>