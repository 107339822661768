import * as React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

export interface MonthProps {
  children: React.ReactNode;
  disabled?: boolean;
  onSelect: (value: any) => void;
  selected?: boolean;
  value: any;
}

export const useStyles = makeStyles(
  theme => ({
    root: {
      flex: '1 0 33.33%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      outline: 'none',
      height: 75,
      transition: theme.transitions.create('font-size', { duration: '100ms' }),
      '&:focus': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    monthSelected: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    monthDisabled: {
      pointerEvents: 'none',
      color: theme.palette.text.hint,
    },
  }),
  { name: 'MuiPickersMonth' }
);

export const Month: React.FC<monthprops> = ({
  selected,
  onSelect,
  disabled,
  value,
  children,
  ...other
}) => {
  const classes = useStyles();
  const handleSelection = React.useCallback(() => {
    onSelect(value);
  }, [onSelect, value]);

  return (
    <typography role="button" component="div" className="{clsx(classes.root," {="" [classes.monthSelected]:="" selected,="" [classes.monthDisabled]:="" disabled,="" })}="" tabIndex="{disabled" ?="" -1="" :="" 0}="" onClick="{handleSelection}" onKeyPress="{handleSelection}" color="{selected" 'primary'="" undefined}="" variant="{selected" 'h5'="" 'subtitle1'}="" children="{children}" {...other}=""></typography>
  );
};

Month.displayName = 'Month';

export default Month;
</monthprops>