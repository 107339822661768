import { cx } from 'flairup';
import * as React from 'react';

import { commonInteractionStyles } from '../../Stylesheet/stylesheet';
import Relative from '../Layout/Relative';
import { CategoryNavigation } from '../navigation/CategoryNavigation';

import { SearchContainer } from './Search/Search';

export function Header() {
  return (
    <relative className="{cx('epr-header'," commonInteractionStyles.hiddenOnReactions)}="">
      <searchcontainer></searchcontainer>
      <categorynavigation></categorynavigation>
    </relative>
  );
}
