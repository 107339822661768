import { produce as createNextState, isDraft } from 'immer';
import type { Draft } from 'immer';
import type { EntityId, DraftableEntityState, PreventAny } from './models';
import type { PayloadAction } from '../createAction';
import { isFSA } from '../createAction';
export const isDraftTyped = (isDraft as <t>(value: T | Draft<t>) => value is Draft<t>);
export function createSingleArgumentStateOperator<t, Id="" extends="" EntityId="">(mutator: (state: DraftableEntityState<t, Id="">) => void) {
  const operator = createStateOperator((_: undefined, state: DraftableEntityState<t, Id="">) => mutator(state));
  return function operation<s extends="" DraftableEntityState<T,="" Id="">>(state: PreventAny<s, T,="" Id="">): S {
    return operator((state as S), undefined);
  };
}
export function createStateOperator<t, Id="" extends="" EntityId,="" R="">(mutator: (arg: R, state: DraftableEntityState<t, Id="">) => void) {
  return function operation<s extends="" DraftableEntityState<T,="" Id="">>(state: S, arg: R | PayloadAction<r>): S {
    function isPayloadActionArgument(arg: R | PayloadAction<r>): arg is PayloadAction<r> {
      return isFSA(arg);
    }
    const runMutator = (draft: DraftableEntityState<t, Id="">) => {
      if (isPayloadActionArgument(arg)) {
        mutator(arg.payload, draft);
      } else {
        mutator(arg, draft);
      }
    };
    if (isDraftTyped<draftableentitystate<t, Id="">>(state)) {
      // we must already be inside a `createNextState` call, likely because
      // this is being wrapped in `createReducer` or `createSlice`.
      // It's safe to just pass the draft to the mutator.
      runMutator(state);

      // since it's a draft, we'll just return it
      return state;
    }
    return createNextState(state, runMutator);
  };
}</draftableentitystate<t,></t,></r></r></r></s></t,></t,></s,></s></t,></t,></t,></t></t></t>