import { formatProdErrorMessage as _formatProdErrorMessage8 } from "@reduxjs/toolkit";
import { formatProdErrorMessage as _formatProdErrorMessage7 } from "@reduxjs/toolkit";
import { formatProdErrorMessage as _formatProdErrorMessage6 } from "@reduxjs/toolkit";
import { formatProdErrorMessage as _formatProdErrorMessage5 } from "@reduxjs/toolkit";
import { formatProdErrorMessage as _formatProdErrorMessage4 } from "@reduxjs/toolkit";
import { formatProdErrorMessage as _formatProdErrorMessage3 } from "@reduxjs/toolkit";
import { formatProdErrorMessage as _formatProdErrorMessage2 } from "@reduxjs/toolkit";
import { formatProdErrorMessage as _formatProdErrorMessage } from "@reduxjs/toolkit";
import type { Action, UnknownAction, Reducer } from 'redux';
import type { Selector } from 'reselect';
import type { ActionCreatorWithoutPayload, PayloadAction, PayloadActionCreator, PrepareAction, _ActionCreatorWithPreparedPayload } from './createAction';
import { createAction } from './createAction';
import type { ActionMatcherDescriptionCollection, CaseReducer, ReducerWithInitialState } from './createReducer';
import { createReducer } from './createReducer';
import type { ActionReducerMapBuilder, TypedActionCreator } from './mapBuilders';
import { executeReducerBuilderCallback } from './mapBuilders';
import type { Id, Tail, TypeGuard } from './tsHelpers';
import type { InjectConfig } from './combineSlices';
import type { AsyncThunk, AsyncThunkConfig, AsyncThunkOptions, AsyncThunkPayloadCreator, OverrideThunkApiConfigs } from './createAsyncThunk';
import { createAsyncThunk as _createAsyncThunk } from './createAsyncThunk';
import { emplace } from './utils';
const asyncThunkSymbol = /* @__PURE__ */Symbol.for('rtk-slice-createasyncthunk');
// type is annotated because it's too long to infer
export const asyncThunkCreator: {
  [asyncThunkSymbol]: typeof _createAsyncThunk;
} = {
  [asyncThunkSymbol]: _createAsyncThunk
};
interface InjectIntoConfig<newreducerpath extends="" string=""> extends InjectConfig {
  reducerPath?: NewReducerPath;
}

/**
 * The return value of `createSlice`
 *
 * @public
 */
export interface Slice<state =="" any,="" CaseReducers="" extends="" SliceCaseReducers<State=""> = SliceCaseReducers<state>, Name extends string = string, ReducerPath extends string = Name, Selectors extends SliceSelectors<state> = SliceSelectors<state>> {
  /**
   * The slice name.
   */
  name: Name;

  /**
   *  The slice reducer path.
   */
  reducerPath: ReducerPath;

  /**
   * The slice's reducer.
   */
  reducer: Reducer<state>;

  /**
   * Action creators for the types of actions that are handled by the slice
   * reducer.
   */
  actions: CaseReducerActions<casereducers, Name="">;

  /**
   * The individual case reducer functions that were passed in the `reducers` parameter.
   * This enables reuse and testing if they were defined inline when calling `createSlice`.
   */
  caseReducers: SliceDefinedCaseReducers<casereducers>;

  /**
   * Provides access to the initial state value given to the slice.
   * If a lazy state initializer was provided, it will be called and a fresh value returned.
   */
  getInitialState: () => State;

  /**
   * Get localised slice selectors (expects to be called with *just* the slice's state as the first parameter)
   */
  getSelectors(): Id<slicedefinedselectors<state, Selectors,="" State="">>;

  /**
   * Get globalised slice selectors (`selectState` callback is expected to receive first parameter and return slice state)
   */
  getSelectors<rootstate>(selectState: (rootState: RootState) => State): Id<slicedefinedselectors<state, Selectors,="" RootState="">>;

  /**
   * Selectors that assume the slice's state is `rootState[slice.reducerPath]` (which is usually the case)
   *
   * Equivalent to `slice.getSelectors((state: RootState) => state[slice.reducerPath])`.
   */
  get selectors(): Id<slicedefinedselectors<state, Selectors,="" {="" [K="" in="" ReducerPath]:="" State="" }="">>;

  /**
   * Inject slice into provided reducer (return value from `combineSlices`), and return injected slice.
   */
  injectInto<newreducerpath extends="" string="ReducerPath">(this: this, injectable: {
    inject: (slice: {
      reducerPath: string;
      reducer: Reducer;
    }, config?: InjectConfig) => void;
  }, config?: InjectIntoConfig<newreducerpath>): InjectedSlice<state, CaseReducers,="" Name,="" NewReducerPath,="" Selectors="">;

  /**
   * Select the slice state, using the slice's current reducerPath.
   *
   * Will throw an error if slice is not found.
   */
  selectSlice(state: { [K in ReducerPath]: State }): State;
}

/**
 * A slice after being called with `injectInto(reducer)`.
 *
 * Selectors can now be called with an `undefined` value, in which case they use the slice's initial state.
 */
interface InjectedSlice<state =="" any,="" CaseReducers="" extends="" SliceCaseReducers<State=""> = SliceCaseReducers<state>, Name extends string = string, ReducerPath extends string = Name, Selectors extends SliceSelectors<state> = SliceSelectors<state>> extends Omit<slice<state, CaseReducers,="" Name,="" ReducerPath,="" Selectors="">, 'getSelectors' | 'selectors'> {
  /**
   * Get localised slice selectors (expects to be called with *just* the slice's state as the first parameter)
   */
  getSelectors(): Id<slicedefinedselectors<state, Selectors,="" State="" |="" undefined="">>;

  /**
   * Get globalised slice selectors (`selectState` callback is expected to receive first parameter and return slice state)
   */
  getSelectors<rootstate>(selectState: (rootState: RootState) => State | undefined): Id<slicedefinedselectors<state, Selectors,="" RootState="">>;

  /**
   * Selectors that assume the slice's state is `rootState[slice.name]` (which is usually the case)
   *
   * Equivalent to `slice.getSelectors((state: RootState) => state[slice.name])`.
   */
  get selectors(): Id<slicedefinedselectors<state, Selectors,="" {="" [K="" in="" ReducerPath]?:="" State="" |="" undefined="" }="">>;

  /**
   * Select the slice state, using the slice's current reducerPath.
   *
   * Returns initial state if slice is not found.
   */
  selectSlice(state: { [K in ReducerPath]?: State | undefined }): State;
}

/**
 * Options for `createSlice()`.
 *
 * @public
 */
export interface CreateSliceOptions<state =="" any,="" CR="" extends="" SliceCaseReducers<State=""> = SliceCaseReducers<state>, Name extends string = string, ReducerPath extends string = Name, Selectors extends SliceSelectors<state> = SliceSelectors<state>> {
  /**
   * The slice's name. Used to namespace the generated action types.
   */
  name: Name;

  /**
   * The slice's reducer path. Used when injecting into a combined slice reducer.
   */
  reducerPath?: ReducerPath;

  /**
   * The initial state that should be used when the reducer is called the first time. This may also be a "lazy initializer" function, which should return an initial state value when called. This will be used whenever the reducer is called with `undefined` as its state value, and is primarily useful for cases like reading initial state from `localStorage`.
   */
  initialState: State | (() => State);

  /**
   * A mapping from action types to action-type-specific *case reducer*
   * functions. For every action type, a matching action creator will be
   * generated using `createAction()`.
   */
  reducers: ValidateSliceCaseReducers<state, CR=""> | ((creators: ReducerCreators<state>) => CR);

  /**
   * A callback that receives a *builder* object to define
   * case reducers via calls to `builder.addCase(actionCreatorOrType, reducer)`.
   * 
   * 
   * @example
  ```ts
  import { createAction, createSlice, Action } from '@reduxjs/toolkit'
  const incrementBy = createAction<number>('incrementBy')
  const decrement = createAction('decrement')
  interface RejectedAction extends Action {
  error: Error
  }
  function isRejectedAction(action: Action): action is RejectedAction {
  return action.type.endsWith('rejected')
  }
  createSlice({
  name: 'counter',
  initialState: 0,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(incrementBy, (state, action) => {
        // action is inferred correctly here if using TS
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      .addCase(decrement, (state, action) => {})
      // You can match a range of action types
      .addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {}
      )
      // and provide a default case if no other handlers matched
      .addDefaultCase((state, action) => {})
    }
  })
  ```
   */
  extraReducers?: (builder: ActionReducerMapBuilder<state>) => void;

  /**
   * A map of selectors that receive the slice's state and any additional arguments, and return a result.
   */
  selectors?: Selectors;
}
export enum ReducerType {
  reducer = 'reducer',
  reducerWithPrepare = 'reducerWithPrepare',
  asyncThunk = 'asyncThunk',
}
interface ReducerDefinition<t extends="" ReducerType="ReducerType"> {
  _reducerDefinitionType: T;
}
export interface CaseReducerDefinition<s =="" any,="" A="" extends="" Action="UnknownAction"> extends CaseReducer<s, A="">, ReducerDefinition<reducertype.reducer> {}

/**
 * A CaseReducer with a `prepare` method.
 *
 * @public
 */
export type CaseReducerWithPrepare<state, Action="" extends="" PayloadAction=""> = {
  reducer: CaseReducer<state, Action="">;
  prepare: PrepareAction<action['payload']>;
};
export interface CaseReducerWithPrepareDefinition<state, Action="" extends="" PayloadAction=""> extends CaseReducerWithPrepare<state, Action="">, ReducerDefinition<reducertype.reducerwithprepare> {}
export interface AsyncThunkSliceReducerConfig<state, ThunkArg="" extends="" any,="" Returned="unknown," ThunkApiConfig="" AsyncThunkConfig="{}"> {
  pending?: CaseReducer<state, ReturnType<AsyncThunk<Returned,="" ThunkArg,="" ThunkApiConfig="">['pending']>>;
  rejected?: CaseReducer<state, ReturnType<AsyncThunk<Returned,="" ThunkArg,="" ThunkApiConfig="">['rejected']>>;
  fulfilled?: CaseReducer<state, ReturnType<AsyncThunk<Returned,="" ThunkArg,="" ThunkApiConfig="">['fulfilled']>>;
  settled?: CaseReducer<state, ReturnType<AsyncThunk<Returned,="" ThunkArg,="" ThunkApiConfig="">['rejected' | 'fulfilled']>>;
  options?: AsyncThunkOptions<thunkarg, ThunkApiConfig="">;
}
export interface AsyncThunkSliceReducerDefinition<state, ThunkArg="" extends="" any,="" Returned="unknown," ThunkApiConfig="" AsyncThunkConfig="{}"> extends AsyncThunkSliceReducerConfig<state, ThunkArg,="" Returned,="" ThunkApiConfig="">, ReducerDefinition<reducertype.asyncthunk> {
  payloadCreator: AsyncThunkPayloadCreator<returned, ThunkArg,="" ThunkApiConfig="">;
}

/**
 * Providing these as part of the config would cause circular types, so we disallow passing them
 */
type PreventCircular<thunkapiconfig> = { [K in keyof ThunkApiConfig]: K extends 'state' | 'dispatch' ? never : ThunkApiConfig[K] };
interface AsyncThunkCreator<state, CurriedThunkApiConfig="" extends="" PreventCircular<AsyncThunkConfig=""> = PreventCircular<asyncthunkconfig>> {
  <returned, ThunkArg="void">(payloadCreator: AsyncThunkPayloadCreator<returned, ThunkArg,="" CurriedThunkApiConfig="">, config?: AsyncThunkSliceReducerConfig<state, ThunkArg,="" Returned,="" CurriedThunkApiConfig="">): AsyncThunkSliceReducerDefinition<state, ThunkArg,="" Returned,="" CurriedThunkApiConfig="">;
  <returned, ThunkArg,="" ThunkApiConfig="" extends="" PreventCircular<AsyncThunkConfig=""> = {}>(payloadCreator: AsyncThunkPayloadCreator<returned, ThunkArg,="" ThunkApiConfig="">, config?: AsyncThunkSliceReducerConfig<state, ThunkArg,="" Returned,="" ThunkApiConfig="">): AsyncThunkSliceReducerDefinition<state, ThunkArg,="" Returned,="" ThunkApiConfig="">;
  withTypes<thunkapiconfig extends="" PreventCircular<AsyncThunkConfig="">>(): AsyncThunkCreator<state, OverrideThunkApiConfigs<CurriedThunkApiConfig,="" ThunkApiConfig="">>;
}
export interface ReducerCreators<state> {
  reducer(caseReducer: CaseReducer<state, PayloadAction="">): CaseReducerDefinition<state, PayloadAction="">;
  reducer<payload>(caseReducer: CaseReducer<state, PayloadAction<Payload="">>): CaseReducerDefinition<state, PayloadAction<Payload="">>;
  asyncThunk: AsyncThunkCreator<state>;
  preparedReducer<prepare extends="" PrepareAction<any="">>(prepare: Prepare, reducer: CaseReducer<state, ReturnType<_ActionCreatorWithPreparedPayload<Prepare="">>>): {
    _reducerDefinitionType: ReducerType.reducerWithPrepare;
    prepare: Prepare;
    reducer: CaseReducer<state, ReturnType<_ActionCreatorWithPreparedPayload<Prepare="">>>;
  };
}

/**
 * The type describing a slice's `reducers` option.
 *
 * @public
 */
export type SliceCaseReducers<state> = Record<string, ReducerDefinition=""> | Record<string, CaseReducer<State,="" PayloadAction<any="">> | CaseReducerWithPrepare<state, PayloadAction<any,="" string,="" any,="" any="">>>;

/**
 * The type describing a slice's `selectors` option.
 */
export type SliceSelectors<state> = {
  [K: string]: (sliceState: State, ...args: any[]) => any;
};
type SliceActionType<slicename extends="" string,="" ActionName="" keyof="" any=""> = ActionName extends string | number ? `${SliceName}/${ActionName}` : string;

/**
 * Derives the slice's `actions` property from the `reducers` options
 *
 * @public
 */
export type CaseReducerActions<casereducers extends="" SliceCaseReducers<any="">, SliceName extends string> = { [Type in keyof CaseReducers]: CaseReducers[Type] extends infer Definition ? Definition extends {
  prepare: any;
} ? ActionCreatorForCaseReducerWithPrepare<definition, SliceActionType<SliceName,="" Type="">> : Definition extends AsyncThunkSliceReducerDefinition<any, infer="" ThunkArg,="" Returned,="" ThunkApiConfig=""> ? AsyncThunk<returned, ThunkArg,="" ThunkApiConfig=""> : Definition extends {
  reducer: any;
} ? ActionCreatorForCaseReducer<definition['reducer'], SliceActionType<SliceName,="" Type="">> : ActionCreatorForCaseReducer<definition, SliceActionType<SliceName,="" Type="">> : never };

/**
 * Get a `PayloadActionCreator` type for a passed `CaseReducerWithPrepare`
 *
 * @internal
 */
type ActionCreatorForCaseReducerWithPrepare<cr extends="" {="" prepare:="" any;="" },="" Type="" string=""> = _ActionCreatorWithPreparedPayload<cr['prepare'], Type="">;

/**
 * Get a `PayloadActionCreator` type for a passed `CaseReducer`
 *
 * @internal
 */
type ActionCreatorForCaseReducer<cr, Type="" extends="" string=""> = CR extends (state: any, action: infer Action) => any ? Action extends {
  payload: infer P;
} ? PayloadActionCreator<p, Type=""> : ActionCreatorWithoutPayload<type> : ActionCreatorWithoutPayload<type>;

/**
 * Extracts the CaseReducers out of a `reducers` object, even if they are
 * tested into a `CaseReducerWithPrepare`.
 *
 * @internal
 */
type SliceDefinedCaseReducers<casereducers extends="" SliceCaseReducers<any="">> = { [Type in keyof CaseReducers]: CaseReducers[Type] extends infer Definition ? Definition extends AsyncThunkSliceReducerDefinition<any, any,="" any=""> ? Id<pick<required<definition>, 'fulfilled' | 'rejected' | 'pending' | 'settled'>> : Definition extends {
  reducer: infer Reducer;
} ? Reducer : Definition : never };
type RemappedSelector<s extends="" Selector,="" NewState=""> = S extends Selector<any, infer="" R,="" P=""> ? Selector<newstate, R,="" P=""> & {
  unwrapped: S;
} : never;

/**
 * Extracts the final selector type from the `selectors` object.
 *
 * Removes the `string` index signature from the default value.
 */
type SliceDefinedSelectors<state, Selectors="" extends="" SliceSelectors<State="">, RootState> = { [K in keyof Selectors as string extends K ? never : K]: RemappedSelector<selectors[k], RootState=""> };

/**
 * Used on a SliceCaseReducers object.
 * Ensures that if a CaseReducer is a `CaseReducerWithPrepare`, that
 * the `reducer` and the `prepare` function use the same type of `payload`.
 *
 * Might do additional such checks in the future.
 *
 * This type is only ever useful if you want to write your own wrapper around
 * `createSlice`. Please don't use it otherwise!
 *
 * @public
 */
export type ValidateSliceCaseReducers<s, ACR="" extends="" SliceCaseReducers<S="">> = ACR & { [T in keyof ACR]: ACR[T] extends {
  reducer(s: S, action?: infer A): any;
} ? {
  prepare(...a: never[]): Omit<a, 'type'="">;
} : {} };
function getType(slice: string, actionKey: string): string {
  return `${slice}/${actionKey}`;
}
interface BuildCreateSliceConfig {
  creators?: {
    asyncThunk?: typeof asyncThunkCreator;
  };
}
export function buildCreateSlice({
  creators
}: BuildCreateSliceConfig = {}) {
  const cAT = creators?.asyncThunk?.[asyncThunkSymbol];
  return function createSlice<state, CaseReducers="" extends="" SliceCaseReducers<State="">, Name extends string, Selectors extends SliceSelectors<state>, ReducerPath extends string = Name>(options: CreateSliceOptions<state, CaseReducers,="" Name,="" ReducerPath,="" Selectors="">): Slice<state, CaseReducers,="" Name,="" ReducerPath,="" Selectors=""> {
    const {
      name,
      reducerPath = ((name as unknown) as ReducerPath)
    } = options;
    if (!name) {
      throw new Error(process.env.NODE_ENV === "production" ? _formatProdErrorMessage(11) : '`name` is a required option for createSlice');
    }
    if (typeof process !== 'undefined' && process.env.NODE_ENV === 'development') {
      if (options.initialState === undefined) {
        console.error('You must provide an `initialState` value that is not `undefined`. You may have misspelled `initialState`');
      }
    }
    const reducers = (typeof options.reducers === 'function' ? options.reducers(buildReducerCreators<state>()) : options.reducers) || {};
    const reducerNames = Object.keys(reducers);
    const context: ReducerHandlingContext<state> = {
      sliceCaseReducersByName: {},
      sliceCaseReducersByType: {},
      actionCreators: {},
      sliceMatchers: []
    };
    const contextMethods: ReducerHandlingContextMethods<state> = {
      addCase(typeOrActionCreator: string | TypedActionCreator<any>, reducer: CaseReducer<state>) {
        const type = typeof typeOrActionCreator === 'string' ? typeOrActionCreator : typeOrActionCreator.type;
        if (!type) {
          throw new Error(process.env.NODE_ENV === "production" ? _formatProdErrorMessage2(12) : '`context.addCase` cannot be called with an empty action type');
        }
        if (type in context.sliceCaseReducersByType) {
          throw new Error(process.env.NODE_ENV === "production" ? _formatProdErrorMessage3(13) : '`context.addCase` cannot be called with two reducers for the same action type: ' + type);
        }
        context.sliceCaseReducersByType[type] = reducer;
        return contextMethods;
      },
      addMatcher(matcher, reducer) {
        context.sliceMatchers.push({
          matcher,
          reducer
        });
        return contextMethods;
      },
      exposeAction(name, actionCreator) {
        context.actionCreators[name] = actionCreator;
        return contextMethods;
      },
      exposeCaseReducer(name, reducer) {
        context.sliceCaseReducersByName[name] = reducer;
        return contextMethods;
      }
    };
    reducerNames.forEach(reducerName => {
      const reducerDefinition = reducers[reducerName];
      const reducerDetails: ReducerDetails = {
        reducerName,
        type: getType(name, reducerName),
        createNotation: typeof options.reducers === 'function'
      };
      if (isAsyncThunkSliceReducerDefinition<state>(reducerDefinition)) {
        handleThunkCaseReducerDefinition(reducerDetails, reducerDefinition, contextMethods, cAT);
      } else {
        handleNormalReducerDefinition<state>(reducerDetails, (reducerDefinition as any), contextMethods);
      }
    });
    function buildReducer() {
      if (process.env.NODE_ENV !== 'production') {
        if (typeof options.extraReducers === 'object') {
          throw new Error(process.env.NODE_ENV === "production" ? _formatProdErrorMessage4(14) : "The object notation for `createSlice.extraReducers` has been removed. Please use the 'builder callback' notation instead: https://redux-toolkit.js.org/api/createSlice");
        }
      }
      const [extraReducers = {}, actionMatchers = [], defaultCaseReducer = undefined] = typeof options.extraReducers === 'function' ? executeReducerBuilderCallback(options.extraReducers) : [options.extraReducers];
      const finalCaseReducers = {
        ...extraReducers,
        ...context.sliceCaseReducersByType
      };
      return createReducer(options.initialState, builder => {
        for (let key in finalCaseReducers) {
          builder.addCase(key, (finalCaseReducers[key] as CaseReducer<any>));
        }
        for (let sM of context.sliceMatchers) {
          builder.addMatcher(sM.matcher, sM.reducer);
        }
        for (let m of actionMatchers) {
          builder.addMatcher(m.matcher, m.reducer);
        }
        if (defaultCaseReducer) {
          builder.addDefaultCase(defaultCaseReducer);
        }
      });
    }
    const selectSelf = (state: State) => state;
    const injectedSelectorCache = new Map<boolean, WeakMap<(rootState:="" any)=""> State | undefined, Record<string, (rootState:="" any)=""> any>>>();
    let _reducer: ReducerWithInitialState<state>;
    function reducer(state: State | undefined, action: UnknownAction) {
      if (!_reducer) _reducer = buildReducer();
      return _reducer(state, action);
    }
    function getInitialState() {
      if (!_reducer) _reducer = buildReducer();
      return _reducer.getInitialState();
    }
    function makeSelectorProps<currentreducerpath extends="" string="ReducerPath">(reducerPath: CurrentReducerPath, injected = false): Pick<slice<state, CaseReducers,="" Name,="" CurrentReducerPath,="" Selectors="">, 'getSelectors' | 'selectors' | 'selectSlice' | 'reducerPath'> {
      function selectSlice(state: { [K in CurrentReducerPath]: State }) {
        let sliceState = state[reducerPath];
        if (typeof sliceState === 'undefined') {
          if (injected) {
            sliceState = getInitialState();
          } else if (process.env.NODE_ENV !== 'production') {
            throw new Error(process.env.NODE_ENV === "production" ? _formatProdErrorMessage5(15) : 'selectSlice returned undefined for an uninjected slice reducer');
          }
        }
        return sliceState;
      }
      function getSelectors(selectState: (rootState: any) => State = selectSelf) {
        const selectorCache = emplace(injectedSelectorCache, injected, {
          insert: () => new WeakMap()
        });
        return (emplace(selectorCache, selectState, {
          insert: () => {
            const map: Record<string, Selector<any,="" any="">> = {};
            for (const [name, selector] of Object.entries(options.selectors ?? {})) {
              map[name] = wrapSelector(selector, selectState, getInitialState, injected);
            }
            return map;
          }
        }) as any);
      }
      return {
        reducerPath,
        getSelectors,
        get selectors() {
          return getSelectors(selectSlice);
        },
        selectSlice
      };
    }
    const slice: Slice<state, CaseReducers,="" Name,="" ReducerPath,="" Selectors=""> = {
      name,
      reducer,
      actions: (context.actionCreators as any),
      caseReducers: (context.sliceCaseReducersByName as any),
      getInitialState,
      ...makeSelectorProps(reducerPath),
      injectInto(injectable, {
        reducerPath: pathOpt,
        ...config
      } = {}) {
        const newReducerPath = pathOpt ?? reducerPath;
        injectable.inject({
          reducerPath: newReducerPath,
          reducer
        }, config);
        return ({
          ...slice,
          ...makeSelectorProps(newReducerPath, true)
        } as any);
      }
    };
    return slice;
  };
}
function wrapSelector<state, NewState,="" S="" extends="" Selector<State="">>(selector: S, selectState: Selector<newstate, State="">, getInitialState: () => State, injected?: boolean) {
  function wrapper(rootState: NewState, ...args: any[]) {
    let sliceState = selectState(rootState);
    if (typeof sliceState === 'undefined') {
      if (injected) {
        sliceState = getInitialState();
      } else if (process.env.NODE_ENV !== 'production') {
        throw new Error(process.env.NODE_ENV === "production" ? _formatProdErrorMessage6(16) : 'selectState returned undefined for an uninjected slice reducer');
      }
    }
    return selector(sliceState, ...args);
  }
  wrapper.unwrapped = selector;
  return (wrapper as RemappedSelector<s, NewState="">);
}

/**
 * A function that accepts an initial state, an object full of reducer
 * functions, and a "slice name", and automatically generates
 * action creators and action types that correspond to the
 * reducers and state.
 *
 * @public
 */
export const createSlice = /* @__PURE__ */buildCreateSlice();
interface ReducerHandlingContext<state> {
  sliceCaseReducersByName: Record<string, CaseReducer<State,="" any=""> | Pick<asyncthunkslicereducerdefinition<state, any,="" any="">, 'fulfilled' | 'rejected' | 'pending' | 'settled'>>;
  sliceCaseReducersByType: Record<string, CaseReducer<State,="" any="">>;
  sliceMatchers: ActionMatcherDescriptionCollection<state>;
  actionCreators: Record<string, Function="">;
}
interface ReducerHandlingContextMethods<state> {
  /**
   * Adds a case reducer to handle a single action type.
   * @param actionCreator - Either a plain action type string, or an action creator generated by [`createAction`](./createAction) that can be used to determine the action type.
   * @param reducer - The actual case reducer function.
   */
  addCase<actioncreator extends="" TypedActionCreator<string="">>(actionCreator: ActionCreator, reducer: CaseReducer<state, ReturnType<ActionCreator="">>): ReducerHandlingContextMethods<state>;
  /**
   * Adds a case reducer to handle a single action type.
   * @param actionCreator - Either a plain action type string, or an action creator generated by [`createAction`](./createAction) that can be used to determine the action type.
   * @param reducer - The actual case reducer function.
   */
  addCase<type extends="" string,="" A="" Action<Type="">>(type: Type, reducer: CaseReducer<state, A="">): ReducerHandlingContextMethods<state>;

  /**
   * Allows you to match incoming actions against your own filter function instead of only the `action.type` property.
   * @remarks
   * If multiple matcher reducers match, all of them will be executed in the order
   * they were defined in - even if a case reducer already matched.
   * All calls to `builder.addMatcher` must come after any calls to `builder.addCase` and before any calls to `builder.addDefaultCase`.
   * @param matcher - A matcher function. In TypeScript, this should be a [type predicate](https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates)
   *   function
   * @param reducer - The actual case reducer function.
   *
   */
  addMatcher<a>(matcher: TypeGuard<a>, reducer: CaseReducer<state, A="" extends="" Action="" ?="" :="" &="">): ReducerHandlingContextMethods<state>;
  /**
   * Add an action to be exposed under the final `slice.actions` key.
   * @param name The key to be exposed as.
   * @param actionCreator The action to expose.
   * @example
   * context.exposeAction("addPost", createAction<post>("addPost"));
   *
   * export const { addPost } = slice.actions
   *
   * dispatch(addPost(post))
   */
  exposeAction(name: string, actionCreator: Function): ReducerHandlingContextMethods<state>;
  /**
   * Add a case reducer to be exposed under the final `slice.caseReducers` key.
   * @param name The key to be exposed as.
   * @param reducer The reducer to expose.
   * @example
   * context.exposeCaseReducer("addPost", (state, action: PayloadAction<post>) => {
   *   state.push(action.payload)
   * })
   *
   * slice.caseReducers.addPost([], addPost(post))
   */
  exposeCaseReducer(name: string, reducer: CaseReducer<state, any=""> | Pick<asyncthunkslicereducerdefinition<state, any,="" any="">, 'fulfilled' | 'rejected' | 'pending' | 'settled'>): ReducerHandlingContextMethods<state>;
}
interface ReducerDetails {
  /** The key the reducer was defined under */
  reducerName: string;
  /** The predefined action type, i.e. `${slice.name}/${reducerName}` */
  type: string;
  /** Whether create. notation was used when defining reducers */
  createNotation: boolean;
}
function buildReducerCreators<state>(): ReducerCreators<state> {
  function asyncThunk(payloadCreator: AsyncThunkPayloadCreator<any, any="">, config: AsyncThunkSliceReducerConfig<state, any="">): AsyncThunkSliceReducerDefinition<state, any=""> {
    return {
      _reducerDefinitionType: ReducerType.asyncThunk,
      payloadCreator,
      ...config
    };
  }
  asyncThunk.withTypes = () => asyncThunk;
  return {
    reducer(caseReducer: CaseReducer<state, any="">) {
      return Object.assign({
        // hack so the wrapping function has the same name as the original
        // we need to create a wrapper so the `reducerDefinitionType` is not assigned to the original
        [caseReducer.name](...args: Parameters<typeof caseReducer="">) {
          return caseReducer(...args);
        }
      }[caseReducer.name], ({
        _reducerDefinitionType: ReducerType.reducer
      } as const));
    },
    preparedReducer(prepare, reducer) {
      return {
        _reducerDefinitionType: ReducerType.reducerWithPrepare,
        prepare,
        reducer
      };
    },
    asyncThunk: (asyncThunk as any)
  };
}
function handleNormalReducerDefinition<state>({
  type,
  reducerName,
  createNotation
}: ReducerDetails, maybeReducerWithPrepare: CaseReducer<state, {="" payload:="" any;="" type:="" string;="" }=""> | CaseReducerWithPrepare<state, PayloadAction<any,="" string,="" any,="" any="">>, context: ReducerHandlingContextMethods<state>) {
  let caseReducer: CaseReducer<state, any="">;
  let prepareCallback: PrepareAction<any> | undefined;
  if ('reducer' in maybeReducerWithPrepare) {
    if (createNotation && !isCaseReducerWithPrepareDefinition(maybeReducerWithPrepare)) {
      throw new Error(process.env.NODE_ENV === "production" ? _formatProdErrorMessage7(17) : 'Please use the `create.preparedReducer` notation for prepared action creators with the `create` notation.');
    }
    caseReducer = maybeReducerWithPrepare.reducer;
    prepareCallback = maybeReducerWithPrepare.prepare;
  } else {
    caseReducer = maybeReducerWithPrepare;
  }
  context.addCase(type, caseReducer).exposeCaseReducer(reducerName, caseReducer).exposeAction(reducerName, prepareCallback ? createAction(type, prepareCallback) : createAction(type));
}
function isAsyncThunkSliceReducerDefinition<state>(reducerDefinition: any): reducerDefinition is AsyncThunkSliceReducerDefinition<state, any,="" any=""> {
  return reducerDefinition._reducerDefinitionType === ReducerType.asyncThunk;
}
function isCaseReducerWithPrepareDefinition<state>(reducerDefinition: any): reducerDefinition is CaseReducerWithPrepareDefinition<state, any=""> {
  return reducerDefinition._reducerDefinitionType === ReducerType.reducerWithPrepare;
}
function handleThunkCaseReducerDefinition<state>({
  type,
  reducerName
}: ReducerDetails, reducerDefinition: AsyncThunkSliceReducerDefinition<state, any,="" any="">, context: ReducerHandlingContextMethods<state>, cAT: typeof _createAsyncThunk | undefined) {
  if (!cAT) {
    throw new Error(process.env.NODE_ENV === "production" ? _formatProdErrorMessage8(18) : 'Cannot use `create.asyncThunk` in the built-in `createSlice`. ' + 'Use `buildCreateSlice({ creators: { asyncThunk: asyncThunkCreator } })` to create a customised version of `createSlice`.');
  }
  const {
    payloadCreator,
    fulfilled,
    pending,
    rejected,
    settled,
    options
  } = reducerDefinition;
  const thunk = cAT(type, payloadCreator, (options as any));
  context.exposeAction(reducerName, thunk);
  if (fulfilled) {
    context.addCase(thunk.fulfilled, fulfilled);
  }
  if (pending) {
    context.addCase(thunk.pending, pending);
  }
  if (rejected) {
    context.addCase(thunk.rejected, rejected);
  }
  if (settled) {
    context.addMatcher(thunk.settled, settled);
  }
  context.exposeCaseReducer(reducerName, {
    fulfilled: fulfilled || noop,
    pending: pending || noop,
    rejected: rejected || noop,
    settled: settled || noop
  });
}
function noop() {}</state></state,></state></state,></state></state,></state></any></state,></state></state,></state,></state></typeof></state,></state,></state,></any,></state></state></state></asyncthunkslicereducerdefinition<state,></state,></post></state></post></state></state,></a></a></state></state,></type></state></state,></actioncreator></state></string,></state></string,></asyncthunkslicereducerdefinition<state,></string,></state></s,></newstate,></state,></state,></string,></slice<state,></currentreducerpath></state></string,></boolean,></any></state></state></state></any></state></state></state></state,></state,></state></state,></a,></s,></selectors[k],></state,></newstate,></any,></s></pick<required<definition></any,></casereducers></type></type></p,></cr,></cr['prepare'],></cr></definition,></definition['reducer'],></returned,></any,></definition,></casereducers></slicename></state></state,></string,></string,></state></state,></state,></prepare></state></state,></state,></payload></state,></state,></state></state,></thunkapiconfig></state,></state,></returned,></returned,></state,></state,></returned,></returned,></asyncthunkconfig></state,></thunkapiconfig></returned,></reducertype.asyncthunk></state,></state,></thunkarg,></state,></state,></state,></state,></state,></reducertype.reducerwithprepare></state,></state,></action['payload']></state,></state,></reducertype.reducer></s,></s></t></state></number></state></state,></state></state></state></state></slicedefinedselectors<state,></slicedefinedselectors<state,></rootstate></slicedefinedselectors<state,></slice<state,></state></state></state></state></state,></newreducerpath></newreducerpath></slicedefinedselectors<state,></slicedefinedselectors<state,></rootstate></slicedefinedselectors<state,></casereducers></casereducers,></state></state></state></state></state></newreducerpath>