import type { Middleware, StoreEnhancer } from 'redux';
import type { Tuple } from './utils';
export function safeAssign<t extends="" object="">(target: T, ...args: Array<partial<noinfer<t>>>) {
  Object.assign(target, ...args);
}

/**
 * return True if T is `any`, otherwise return False
 * taken from https://github.com/joonhocho/tsdef
 *
 * @internal
 */
export type IsAny<t, True,="" False="never"> =
// test if we are going the left AND right path in the condition
true | false extends (T extends never ? true : false) ? True : False;
export type CastAny<t, CastTo=""> = IsAny<t, CastTo,="" T="">;

/**
 * return True if T is `unknown`, otherwise return False
 * taken from https://github.com/joonhocho/tsdef
 *
 * @internal
 */
export type IsUnknown<t, True,="" False="never"> = unknown extends T ? IsAny<t, False,="" True=""> : False;
export type FallbackIfUnknown<t, Fallback=""> = IsUnknown<t, Fallback,="" T="">;

/**
 * @internal
 */
export type IfMaybeUndefined<p, True,="" False=""> = [undefined] extends [P] ? True : False;

/**
 * @internal
 */
export type IfVoid<p, True,="" False=""> = [void] extends [P] ? True : False;

/**
 * @internal
 */
export type IsEmptyObj<t, True,="" False="never"> = T extends any ? keyof T extends never ? IsUnknown<t, False,="" IfMaybeUndefined<T,="" IfVoid<T,="" True="">>> : False : never;

/**
 * returns True if TS version is above 3.5, False if below.
 * uses feature detection to detect TS version >= 3.5
 * * versions below 3.5 will return `{}` for unresolvable interference
 * * versions above will return `unknown`
 *
 * @internal
 */
export type AtLeastTS35<true, False=""> = [True, False][IsUnknown<returntype<<t>() => T>, 0, 1>];

/**
 * @internal
 */
export type IsUnknownOrNonInferrable<t, True,="" False=""> = AtLeastTS35<isunknown<t, True,="" False="">, IsEmptyObj<t, True,="" IsUnknown<T,="" False="">>>;

/**
 * Convert a Union type `(A|B)` to an intersection type `(A&B)`
 */
export type UnionToIntersection<u> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

// Appears to have a convenient side effect of ignoring `never` even if that's not what you specified
export type ExcludeFromTuple<t, E,="" Acc="" extends="" unknown[]="[]"> = T extends [infer Head, ...infer Tail] ? ExcludeFromTuple<tail, E,="" [...Acc,="" ...([Head]="" extends="" [E]="" ?="" []="" :="" [Head])]=""> : Acc;
type ExtractDispatchFromMiddlewareTuple<middlewaretuple extends="" readonly="" any[],="" Acc="" {}=""> = MiddlewareTuple extends [infer Head, ...infer Tail] ? ExtractDispatchFromMiddlewareTuple<tail, Acc="" &="" (Head="" extends="" Middleware<infer="" D=""> ? IsAny<d, {},="" D=""> : {})> : Acc;
export type ExtractDispatchExtensions<m> = M extends Tuple<infer MiddlewareTuple=""> ? ExtractDispatchFromMiddlewareTuple<middlewaretuple, {}=""> : M extends ReadonlyArray<middleware> ? ExtractDispatchFromMiddlewareTuple<[...M], {}> : never;
type ExtractStoreExtensionsFromEnhancerTuple<enhancertuple extends="" readonly="" any[],="" Acc="" {}=""> = EnhancerTuple extends [infer Head, ...infer Tail] ? ExtractStoreExtensionsFromEnhancerTuple<tail, Acc="" &="" (Head="" extends="" StoreEnhancer<infer="" Ext=""> ? IsAny<ext, {},="" Ext=""> : {})> : Acc;
export type ExtractStoreExtensions<e> = E extends Tuple<infer EnhancerTuple=""> ? ExtractStoreExtensionsFromEnhancerTuple<enhancertuple, {}=""> : E extends ReadonlyArray<storeenhancer> ? UnionToIntersection<e[number] extends="" StoreEnhancer<infer="" Ext=""> ? Ext extends {} ? IsAny<ext, {},="" Ext=""> : {} : {}> : never;
type ExtractStateExtensionsFromEnhancerTuple<enhancertuple extends="" readonly="" any[],="" Acc="" {}=""> = EnhancerTuple extends [infer Head, ...infer Tail] ? ExtractStateExtensionsFromEnhancerTuple<tail, Acc="" &="" (Head="" extends="" StoreEnhancer<any,="" infer="" StateExt=""> ? IsAny<stateext, {},="" StateExt=""> : {})> : Acc;
export type ExtractStateExtensions<e> = E extends Tuple<infer EnhancerTuple=""> ? ExtractStateExtensionsFromEnhancerTuple<enhancertuple, {}=""> : E extends ReadonlyArray<storeenhancer> ? UnionToIntersection<e[number] extends="" StoreEnhancer<any,="" infer="" StateExt=""> ? StateExt extends {} ? IsAny<stateext, {},="" StateExt=""> : {} : {}> : never;

/**
 * Helper type. Passes T out again, but boxes it in a way that it cannot
 * "widen" the type by accident if it is a generic that should be inferred
 * from elsewhere.
 *
 * @internal
 */
export type NoInfer<t> = [T][T extends any ? 0 : never];
export type NonUndefined<t> = T extends undefined ? never : T;
export type Omit<t, K="" extends="" keyof="" any=""> = Pick<t, Exclude<keyof="" T,="" K="">>;
export type WithRequiredProp<t, K="" extends="" keyof="" T=""> = Omit<t, K=""> & Required<pick<t, K="">>;
export type WithOptionalProp<t, K="" extends="" keyof="" T=""> = Omit<t, K=""> & Partial<pick<t, K="">>;
export interface TypeGuard<t> {
  (value: any): value is T;
}
export interface HasMatchFunction<t> {
  match: TypeGuard<t>;
}
export const hasMatchFunction = <t,>(v: Matcher<t>): v is HasMatchFunction<t> => {
  return v && typeof (v as HasMatchFunction<t>).match === 'function';
};

/** @public */
export type Matcher<t> = HasMatchFunction<t> | TypeGuard<t>;

/** @public */
export type ActionFromMatcher<m extends="" Matcher<any="">> = M extends Matcher<infer T=""> ? T : never;
export type Id<t> = { [K in keyof T]: T[K] } & {};
export type Tail<t extends="" any[]=""> = T extends [any, ...infer Tail] ? Tail : never;
export type UnknownIfNonSpecific<t> = {} extends T ? unknown : T;

/**
 * A Promise that will never reject.
 * @see https://github.com/reduxjs/redux-toolkit/issues/4101
 */
export type SafePromise<t> = Promise<t> & {
  __linterBrands: 'SafePromise';
};

/**
 * Properly wraps a Promise as a {@link SafePromise} with .catch(fallback).
 */
export function asSafePromise<resolved, Rejected="">(promise: Promise<resolved>, fallback: (error: unknown) => Rejected) {
  return (promise.catch(fallback) as SafePromise<resolved |="" Rejected="">);
}</resolved></resolved></resolved,></t></t></t></t></t></infer></m></t></t></t></t></t></t></t,></t></t></t></pick<t,></t,></t,></pick<t,></t,></t,></t,></t,></t></t></stateext,></e[number]></storeenhancer></enhancertuple,></infer></e></stateext,></tail,></enhancertuple></ext,></e[number]></storeenhancer></enhancertuple,></infer></e></ext,></tail,></enhancertuple></middleware></middlewaretuple,></infer></m></d,></tail,></middlewaretuple></tail,></t,></u></t,></isunknown<t,></t,></returntype<<t></true,></t,></t,></p,></p,></t,></t,></t,></t,></t,></t,></t,></partial<noinfer<t></t>