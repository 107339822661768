import * as React from 'react';
import { Omit } from '../_helpers/utils';
import { StaticWrapper } from './StaticWrapper';
import { ModalWrapper, ModalWrapperProps } from './ModalWrapper';
import { InlineWrapper, InlineWrapperProps } from './InlineWrapper';
import { KeyboardDateInputProps } from '../_shared/KeyboardDateInput';
import { PureDateInputProps, NotOverridableProps } from '../_shared/PureDateInput';

export type WrapperVariant = 'dialog' | 'inline' | 'static';

export interface WrapperProps<t> {
  open: boolean;
  onAccept: () => void;
  onDismiss: () => void;
  onClear: () => void;
  onSetToday: () => void;
  InputComponent: React.FC<t>;
  DateInputProps: T;
  wider?: boolean;
  showTabs?: boolean;
}

type OmitInnerWrapperProps<t extends="" WrapperProps<any="">> = Omit<
  T,
  keyof WrapperProps<any> | 'showTabs'
>;

export type ModalRoot = OmitInnerWrapperProps<modalwrapperprops>;

export type InlineRoot = OmitInnerWrapperProps<inlinewrapperprops>;

// prettier-ignore
export type ExtendWrapper<tinput extends="" PureDateInputProps="" |="" KeyboardDateInputProps=""> = {
  /**
   * Picker container option
   * @default 'dialog'
   */
  variant?: WrapperVariant
} & ModalRoot
  & InlineRoot
  & Omit<tinput, NotOverridableProps="">

export function getWrapperFromVariant<t>(
  variant?: WrapperVariant
): React.ComponentType<inlinewrapperprops<t> | ModalWrapperProps<t>> {
  switch (variant) {
    case 'inline':
      return InlineWrapper as any;

    case 'static':
      return StaticWrapper as any;

    default:
      return ModalWrapper as any;
  }
}

type Props<t> = {
  variant?: WrapperVariant;
  children?: React.ReactChild;
} & (ModalWrapperProps<t> | InlineWrapperProps<t>);

export const VariantContext = React.createContext<wrappervariant |="" null="">(null);

export const Wrapper: <t extends="" KeyboardDateInputProps="" |="" PureDateInputProps="">(
  p: Props<t>
) => React.ReactElement<props<t>> = ({ variant, ...props }) => {
  const Component = getWrapperFromVariant<typeof props.DateInputProps="">(variant);

  return (
    <variantcontext.provider value="{variant" ||="" 'dialog'}="">
      <component {...props}=""></component>
    </variantcontext.provider>
  );
};
</typeof></props<t></t></t></wrappervariant></t></t></t></t></inlinewrapperprops<t></t></tinput,></tinput></inlinewrapperprops></modalwrapperprops></any></t></t></t>