import React from 'react'
import { Text, Element } from 'slate'

import String from './string'
import { PLACEHOLDER_SYMBOL } from '../utils/weak-maps'
import { RenderLeafProps } from './editable'

/**
 * Individual leaves in a text node with unique formatting.
 */

const Leaf = (props: {
  isLast: boolean
  leaf: Text
  parent: Element
  renderLeaf?: (props: RenderLeafProps) => JSX.Element
  text: Text
}) => {
  const {
    leaf,
    isLast,
    text,
    parent,
    renderLeaf = (props: RenderLeafProps) => <defaultleaf {...props}=""></defaultleaf>,
  } = props

  let children = (
    <string isLast="{isLast}" leaf="{leaf}" parent="{parent}" text="{text}"></string>
  )

  if (leaf[PLACEHOLDER_SYMBOL]) {
    children = (
      <react.fragment>
        <span contentEditable="{false}" style="{{" pointerEvents:="" 'none',="" display:="" 'inline-block',="" width:="" '0',="" maxWidth:="" '100%',="" whiteSpace:="" 'nowrap',="" opacity:="" '0.333',="" userSelect:="" fontStyle:="" 'normal',="" fontWeight:="" textDecoration:="" }}="">
          {leaf.placeholder as React.ReactNode}
        </span>
        {children}
      </react.fragment>
    )
  }

  // COMPAT: Having the `data-` attributes on these leaf elements ensures that
  // in certain misbehaving browsers they aren't weirdly cloned/destroyed by
  // contenteditable behaviors. (2019/05/08)
  const attributes: {
    'data-slate-leaf': true
  } = {
    'data-slate-leaf': true,
  }

  return renderLeaf({ attributes, children, leaf, text })
}

const MemoizedLeaf = React.memo(Leaf, (prev, next) => {
  return (
    next.parent === prev.parent &&
    next.isLast === prev.isLast &&
    next.renderLeaf === prev.renderLeaf &&
    next.text === prev.text &&
    Text.matches(next.leaf, prev.leaf)
  )
})

/**
 * The default custom leaf renderer.
 */

export const DefaultLeaf = (props: RenderLeafProps) => {
  const { attributes, children } = props
  return <span {...attributes}="">{children}</span>
}

export default MemoizedLeaf
