import { Node, Ancestor, Editor, Range } from 'slate'

import { Key } from './key'

/**
 * Two weak maps that allow us rebuild a path given a node. They are populated
 * at render time such that after a render occurs we can always backtrack.
 */

export const NODE_TO_INDEX: WeakMap<node, number=""> = new WeakMap()
export const NODE_TO_PARENT: WeakMap<node, Ancestor=""> = new WeakMap()

/**
 * Weak maps that allow us to go between Slate nodes and DOM nodes. These
 * are used to resolve DOM event-related logic into Slate actions.
 */

export const EDITOR_TO_ELEMENT: WeakMap<editor, HTMLElement=""> = new WeakMap()
export const EDITOR_TO_PLACEHOLDER: WeakMap<editor, string=""> = new WeakMap()
export const ELEMENT_TO_NODE: WeakMap<htmlelement, Node=""> = new WeakMap()
export const KEY_TO_ELEMENT: WeakMap<key, HTMLElement=""> = new WeakMap()
export const NODE_TO_ELEMENT: WeakMap<node, HTMLElement=""> = new WeakMap()
export const NODE_TO_KEY: WeakMap<node, Key=""> = new WeakMap()

/**
 * Weak maps for storing editor-related state.
 */

export const IS_READ_ONLY: WeakMap<editor, boolean=""> = new WeakMap()
export const IS_FOCUSED: WeakMap<editor, boolean=""> = new WeakMap()
export const IS_DRAGGING: WeakMap<editor, boolean=""> = new WeakMap()
export const IS_CLICKING: WeakMap<editor, boolean=""> = new WeakMap()

/**
 * Weak map for associating the context `onChange` context with the plugin.
 */

export const EDITOR_TO_ON_CHANGE = new WeakMap<editor, ()=""> void>()

/**
 * Symbols.
 */

export const PLACEHOLDER_SYMBOL = (Symbol('placeholder') as unknown) as string
</editor,></editor,></editor,></editor,></editor,></node,></node,></key,></htmlelement,></editor,></editor,></node,></node,>