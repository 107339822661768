/*
 * This file is part of the nivo project.
 *
 * Copyright 2016-present, Raphaël Benitte.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { Fragment, useState, useMemo } from 'react'
import {
    bindDefs,
    withContainer,
    useDimensions,
    useTheme,
    SvgWrapper,
    CartesianMarkers,
} from '@nivo/core'
import { useInheritedColor } from '@nivo/colors'
import { Axes, Grid } from '@nivo/axes'
import { BoxLegendSvg } from '@nivo/legends'
import { Crosshair } from '@nivo/tooltip'
import { useLine } from './hooks'
import { LinePropTypes, LineDefaultProps } from './props'
import Areas from './Areas'
import Lines from './Lines'
import Slices from './Slices'
import Points from './Points'
import Mesh from './Mesh'

const Line = props => {
    const {
        data,
        xScale: xScaleSpec,
        xFormat,
        yScale: yScaleSpec,
        yFormat,
        layers,
        curve,
        areaBaselineValue,

        colors,

        margin: partialMargin,
        width,
        height,

        axisTop,
        axisRight,
        axisBottom,
        axisLeft,
        enableGridX,
        enableGridY,
        gridXValues,
        gridYValues,

        lineWidth,
        enableArea,
        areaOpacity,
        areaBlendMode,

        enablePoints,
        pointSymbol,
        pointSize,
        pointColor,
        pointBorderWidth,
        pointBorderColor,
        enablePointLabel,
        pointLabel,
        pointLabelYOffset,

        defs,
        fill,

        markers,

        legends,

        isInteractive,

        useMesh,
        debugMesh,

        onMouseEnter,
        onMouseMove,
        onMouseLeave,
        onClick,

        tooltip,

        enableSlices,
        debugSlices,
        sliceTooltip,

        enableCrosshair,
        crosshairType,

        role,
    } = props

    const { margin, innerWidth, innerHeight, outerWidth, outerHeight } = useDimensions(
        width,
        height,
        partialMargin
    )

    const { lineGenerator, areaGenerator, series, xScale, yScale, slices, points } = useLine({
        data,
        xScale: xScaleSpec,
        xFormat,
        yScale: yScaleSpec,
        yFormat,
        width: innerWidth,
        height: innerHeight,
        colors,
        curve,
        areaBaselineValue,
        pointColor,
        pointBorderColor,
        enableSlices,
    })

    const theme = useTheme()
    const getPointColor = useInheritedColor(pointColor, theme)
    const getPointBorderColor = useInheritedColor(pointBorderColor, theme)

    const [currentPoint, setCurrentPoint] = useState(null)
    const [currentSlice, setCurrentSlice] = useState(null)

    const legendData = useMemo(
        () =>
            series
                .map(line => ({
                    id: line.id,
                    label: line.id,
                    color: line.color,
                }))
                .reverse(),
        [series]
    )

    const layerById = {
        grid: (
            <grid key="grid" theme="{theme}" width="{innerWidth}" height="{innerHeight}" xScale="{enableGridX" ?="" :="" null}="" yScale="{enableGridY" xValues="{gridXValues}" yValues="{gridYValues}"></grid>
        ),
        markers: (
            <cartesianmarkers key="markers" markers="{markers}" width="{innerWidth}" height="{innerHeight}" xScale="{xScale}" yScale="{yScale}" theme="{theme}"></cartesianmarkers>
        ),
        axes: (
            <axes key="axes" xScale="{xScale}" yScale="{yScale}" width="{innerWidth}" height="{innerHeight}" theme="{theme}" top="{axisTop}" right="{axisRight}" bottom="{axisBottom}" left="{axisLeft}"></axes>
        ),
        areas: null,
        lines: (
            <lines key="lines" lines="{series}" lineGenerator="{lineGenerator}" lineWidth="{lineWidth}"></lines>
        ),
        slices: null,
        points: null,
        crosshair: null,
        mesh: null,
        legends: legends.map((legend, i) => (
            <boxlegendsvg key="{`legend.${i}`}" {...legend}="" containerWidth="{innerWidth}" containerHeight="{innerHeight}" data="{legend.data" ||="" legendData}="" theme="{theme}"></boxlegendsvg>
        )),
    }

    const boundDefs = bindDefs(defs, series, fill)

    if (enableArea) {
        layerById.areas = (
            <areas key="areas" areaGenerator="{areaGenerator}" areaOpacity="{areaOpacity}" areaBlendMode="{areaBlendMode}" lines="{series}"></areas>
        )
    }

    if (isInteractive && enableSlices !== false) {
        layerById.slices = (
            <slices key="slices" slices="{slices}" axis="{enableSlices}" debug="{debugSlices}" height="{innerHeight}" tooltip="{sliceTooltip}" current="{currentSlice}" setCurrent="{setCurrentSlice}"></slices>
        )
    }

    if (enablePoints) {
        layerById.points = (
            <points key="points" points="{points}" symbol="{pointSymbol}" size="{pointSize}" color="{getPointColor}" borderWidth="{pointBorderWidth}" borderColor="{getPointBorderColor}" enableLabel="{enablePointLabel}" label="{pointLabel}" labelYOffset="{pointLabelYOffset}"></points>
        )
    }

    if (isInteractive && enableCrosshair) {
        if (currentPoint !== null) {
            layerById.crosshair = (
                <crosshair key="crosshair" width="{innerWidth}" height="{innerHeight}" x="{currentPoint.x}" y="{currentPoint.y}" type="{crosshairType}"></crosshair>
            )
        }
        if (currentSlice !== null) {
            layerById.crosshair = (
                <crosshair key="crosshair" width="{innerWidth}" height="{innerHeight}" x="{currentSlice.x}" y="{currentSlice.y}" type="{enableSlices}"></crosshair>
            )
        }
    }

    if (isInteractive && useMesh && enableSlices === false) {
        layerById.mesh = (
            <mesh key="mesh" points="{points}" width="{innerWidth}" height="{innerHeight}" margin="{margin}" current="{currentPoint}" setCurrent="{setCurrentPoint}" onMouseEnter="{onMouseEnter}" onMouseMove="{onMouseMove}" onMouseLeave="{onMouseLeave}" onClick="{onClick}" tooltip="{tooltip}" debug="{debugMesh}"></mesh>
        )
    }

    return (
        <svgwrapper defs="{boundDefs}" width="{outerWidth}" height="{outerHeight}" margin="{margin}" role="{role}">
            {layers.map((layer, i) => {
                if (typeof layer === 'function') {
                    return (
                        <fragment key="{i}">
                            {layer({
                                ...props,
                                innerWidth,
                                innerHeight,
                                series,
                                slices,
                                points,
                                xScale,
                                yScale,
                                lineGenerator,
                                areaGenerator,
                                currentPoint,
                                setCurrentPoint,
                                currentSlice,
                                setCurrentSlice,
                            })}
                        </fragment>
                    )
                }

                return layerById[layer]
            })}
        </svgwrapper>
    )
}

Line.propTypes = LinePropTypes
Line.defaultProps = LineDefaultProps

export default withContainer(Line)
