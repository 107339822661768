import type { SerializedError } from '@reduxjs/toolkit';
import type { BaseQueryError } from '../baseQueryTypes';
import type { QueryDefinition, MutationDefinition, EndpointDefinitions, BaseEndpointDefinition, ResultTypeFrom, QueryArgFrom } from '../endpointDefinitions';
import type { Id, WithRequiredProp } from '../tsHelpers';
export type QueryCacheKey = string & {
  _type: 'queryCacheKey';
};
export type QuerySubstateIdentifier = {
  queryCacheKey: QueryCacheKey;
};
export type MutationSubstateIdentifier = {
  requestId: string;
  fixedCacheKey?: string;
} | {
  requestId?: string;
  fixedCacheKey: string;
};
export type RefetchConfigOptions = {
  refetchOnMountOrArgChange: boolean | number;
  refetchOnReconnect: boolean;
  refetchOnFocus: boolean;
};

/**
 * Strings describing the query state at any given time.
 */
export enum QueryStatus {
  uninitialized = 'uninitialized',
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}
export type RequestStatusFlags = {
  status: QueryStatus.uninitialized;
  isUninitialized: true;
  isLoading: false;
  isSuccess: false;
  isError: false;
} | {
  status: QueryStatus.pending;
  isUninitialized: false;
  isLoading: true;
  isSuccess: false;
  isError: false;
} | {
  status: QueryStatus.fulfilled;
  isUninitialized: false;
  isLoading: false;
  isSuccess: true;
  isError: false;
} | {
  status: QueryStatus.rejected;
  isUninitialized: false;
  isLoading: false;
  isSuccess: false;
  isError: true;
};
export function getRequestStatusFlags(status: QueryStatus): RequestStatusFlags {
  return ({
    status,
    isUninitialized: status === QueryStatus.uninitialized,
    isLoading: status === QueryStatus.pending,
    isSuccess: status === QueryStatus.fulfilled,
    isError: status === QueryStatus.rejected
  } as any);
}
export type SubscriptionOptions = {
  /**
   * How frequently to automatically re-fetch data (in milliseconds). Defaults to `0` (off).
   */
  pollingInterval?: number;
  /**
   *  Defaults to 'false'. This setting allows you to control whether RTK Query will continue polling if the window is not focused.
   *
   *  If pollingInterval is not set or set to 0, this **will not be evaluated** until pollingInterval is greater than 0.
   *
   *  Note: requires [`setupListeners`](./setupListeners) to have been called.
   */
  skipPollingIfUnfocused?: boolean;
  /**
   * Defaults to `false`. This setting allows you to control whether RTK Query will try to refetch all subscribed queries after regaining a network connection.
   *
   * If you specify this option alongside `skip: true`, this **will not be evaluated** until `skip` is false.
   *
   * Note: requires [`setupListeners`](./setupListeners) to have been called.
   */
  refetchOnReconnect?: boolean;
  /**
   * Defaults to `false`. This setting allows you to control whether RTK Query will try to refetch all subscribed queries after the application window regains focus.
   *
   * If you specify this option alongside `skip: true`, this **will not be evaluated** until `skip` is false.
   *
   * Note: requires [`setupListeners`](./setupListeners) to have been called.
   */
  refetchOnFocus?: boolean;
};
export type Subscribers = {
  [requestId: string]: SubscriptionOptions;
};
export type QueryKeys<definitions extends="" EndpointDefinitions=""> = { [K in keyof Definitions]: Definitions[K] extends QueryDefinition<any, any,="" any=""> ? K : never }[keyof Definitions];
export type MutationKeys<definitions extends="" EndpointDefinitions=""> = { [K in keyof Definitions]: Definitions[K] extends MutationDefinition<any, any,="" any=""> ? K : never }[keyof Definitions];
type BaseQuerySubState<d extends="" BaseEndpointDefinition<any,="" any,="" any="">> = {
  /**
   * The argument originally passed into the hook or `initiate` action call
   */
  originalArgs: QueryArgFrom<d>;
  /**
   * A unique ID associated with the request
   */
  requestId: string;
  /**
   * The received data from the query
   */
  data?: ResultTypeFrom<d>;
  /**
   * The received error if applicable
   */
  error?: SerializedError | (D extends QueryDefinition<any, infer="" BaseQuery,="" any,="" any=""> ? BaseQueryError<basequery> : never);
  /**
   * The name of the endpoint associated with the query
   */
  endpointName: string;
  /**
   * Time that the latest query started
   */
  startedTimeStamp: number;
  /**
   * Time that the latest query was fulfilled
   */
  fulfilledTimeStamp?: number;
};
export type QuerySubState<d extends="" BaseEndpointDefinition<any,="" any,="" any="">> = Id<({
  status: QueryStatus.fulfilled;
} & WithRequiredProp<basequerysubstate<d>, 'data' | 'fulfilledTimeStamp'> & {
  error: undefined;
}) | ({
  status: QueryStatus.pending;
} & BaseQuerySubState<d>) | ({
  status: QueryStatus.rejected;
} & WithRequiredProp<basequerysubstate<d>, 'error'>) | {
  status: QueryStatus.uninitialized;
  originalArgs?: undefined;
  data?: undefined;
  error?: undefined;
  requestId?: undefined;
  endpointName?: string;
  startedTimeStamp?: undefined;
  fulfilledTimeStamp?: undefined;
}>;
type BaseMutationSubState<d extends="" BaseEndpointDefinition<any,="" any,="" any="">> = {
  requestId: string;
  data?: ResultTypeFrom<d>;
  error?: SerializedError | (D extends MutationDefinition<any, infer="" BaseQuery,="" any,="" any=""> ? BaseQueryError<basequery> : never);
  endpointName: string;
  startedTimeStamp: number;
  fulfilledTimeStamp?: number;
};
export type MutationSubState<d extends="" BaseEndpointDefinition<any,="" any,="" any="">> = (({
  status: QueryStatus.fulfilled;
} & WithRequiredProp<basemutationsubstate<d>, 'data' | 'fulfilledTimeStamp'>) & {
  error: undefined;
}) | (({
  status: QueryStatus.pending;
} & BaseMutationSubState<d>) & {
  data?: undefined;
}) | ({
  status: QueryStatus.rejected;
} & WithRequiredProp<basemutationsubstate<d>, 'error'>) | {
  requestId?: undefined;
  status: QueryStatus.uninitialized;
  data?: undefined;
  error?: undefined;
  endpointName?: string;
  startedTimeStamp?: undefined;
  fulfilledTimeStamp?: undefined;
};
export type CombinedState<d extends="" EndpointDefinitions,="" E="" string,="" ReducerPath="" string=""> = {
  queries: QueryState<d>;
  mutations: MutationState<d>;
  provided: InvalidationState<e>;
  subscriptions: SubscriptionState;
  config: ConfigState<reducerpath>;
};
export type InvalidationState<tagtypes extends="" string=""> = { [_ in TagTypes]: {
  [id: string]: Array<querycachekey>;
  [id: number]: Array<querycachekey>;
} };
export type QueryState<d extends="" EndpointDefinitions=""> = {
  [queryCacheKey: string]: QuerySubState<d[string]> | undefined;
};
export type SubscriptionState = {
  [queryCacheKey: string]: Subscribers | undefined;
};
export type ConfigState<reducerpath> = RefetchConfigOptions & {
  reducerPath: ReducerPath;
  online: boolean;
  focused: boolean;
  middlewareRegistered: boolean | 'conflict';
} & ModifiableConfigState;
export type ModifiableConfigState = {
  keepUnusedDataFor: number;
  invalidationBehavior: 'delayed' | 'immediately';
} & RefetchConfigOptions;
export type MutationState<d extends="" EndpointDefinitions=""> = {
  [requestId: string]: MutationSubState<d[string]> | undefined;
};
export type RootState<definitions extends="" EndpointDefinitions,="" TagTypes="" string,="" ReducerPath="" string=""> = { [P in ReducerPath]: CombinedState<definitions, TagTypes,="" P=""> };</definitions,></definitions></d[string]></d></reducerpath></d[string]></d></querycachekey></querycachekey></tagtypes></reducerpath></e></d></d></d></basemutationsubstate<d></d></basemutationsubstate<d></d></basequery></any,></d></d></basequerysubstate<d></d></basequerysubstate<d></d></basequery></any,></d></d></d></any,></definitions></any,></definitions>