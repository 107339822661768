import * as React from 'react';
import { BasePickerProps } from '../typings/BasePicker';
import { Picker, ToolbarComponentProps } from './Picker';
import { ExtendWrapper, Wrapper } from '../wrappers/Wrapper';
import { PureDateInputProps } from '../_shared/PureDateInput';
import { DateValidationProps } from '../_helpers/text-field-helper';
import { KeyboardDateInputProps } from '../_shared/KeyboardDateInput';
import { StateHookOptions, usePickerState } from '../_shared/hooks/usePickerState';
import {
  BaseKeyboardPickerProps,
  useKeyboardPickerState,
} from '../_shared/hooks/useKeyboardPickerState';

export type WithKeyboardInputProps = DateValidationProps &
  BaseKeyboardPickerProps &
  ExtendWrapper<keyboarddateinputprops>;

export type WithPureInputProps = DateValidationProps &
  BasePickerProps &
  ExtendWrapper<puredateinputprops>;

export interface MakePickerOptions<t extends="" any=""> {
  Input: any;
  useState: typeof usePickerState | typeof useKeyboardPickerState;
  useOptions: (props: any) => StateHookOptions;
  getCustomProps?: (props: T) => Partial<t>;
  DefaultToolbarComponent: React.ComponentType<toolbarcomponentprops>;
}

export function makePickerWithState<t extends="" any="">({
  Input,
  useState,
  useOptions,
  getCustomProps,
  DefaultToolbarComponent,
}: MakePickerOptions<t>): React.FC<t> {
  function PickerWithState(props: T) {
    const {
      allowKeyboardControl,
      ampm,
      animateYearScrolling,
      autoOk,
      dateRangeIcon,
      disableFuture,
      disablePast,
      disableToolbar,
      emptyLabel,
      format,
      forwardedRef,
      hideTabs,
      initialFocusedDate,
      invalidDateMessage,
      invalidLabel,
      labelFunc,
      leftArrowButtonProps,
      leftArrowIcon,
      loadingIndicator,
      maxDate,
      maxDateMessage,
      minDate,
      minDateMessage,
      minutesStep,
      onAccept,
      onChange,
      onClose,
      onMonthChange,
      onOpen,
      onYearChange,
      openTo,
      orientation,
      renderDay,
      rightArrowButtonProps,
      rightArrowIcon,
      shouldDisableDate,
      strictCompareDates,
      timeIcon,
      ToolbarComponent = DefaultToolbarComponent,
      value,
      variant,
      views,
      ...other
    } = props;

    const injectedProps = getCustomProps ? getCustomProps(props) : {};

    const options = useOptions(props);
    const { pickerProps, inputProps, wrapperProps } = useState(props as any, options);

    return (
      <wrapper variant="{variant}" InputComponent="{Input}" DateInputProps="{inputProps}" {...injectedProps}="" {...wrapperProps}="" {...other}="">
        <picker {...pickerProps}="" allowKeyboardControl="{allowKeyboardControl}" ampm="{ampm}" animateYearScrolling="{animateYearScrolling}" dateRangeIcon="{dateRangeIcon}" disableFuture="{disableFuture}" disablePast="{disablePast}" disableToolbar="{disableToolbar}" hideTabs="{hideTabs}" leftArrowButtonProps="{leftArrowButtonProps}" leftArrowIcon="{leftArrowIcon}" loadingIndicator="{loadingIndicator}" maxDate="{maxDate}" minDate="{minDate}" minutesStep="{minutesStep}" onMonthChange="{onMonthChange}" onYearChange="{onYearChange}" openTo="{openTo}" orientation="{orientation}" renderDay="{renderDay}" rightArrowButtonProps="{rightArrowButtonProps}" rightArrowIcon="{rightArrowIcon}" shouldDisableDate="{shouldDisableDate}" strictCompareDates="{strictCompareDates}" timeIcon="{timeIcon}" ToolbarComponent="{ToolbarComponent}" views="{views}"></picker>
      </wrapper>
    );
  }

  return PickerWithState;
}
</t></t></t></toolbarcomponentprops></t></t></puredateinputprops></keyboarddateinputprops>