import { formatProdErrorMessage as _formatProdErrorMessage } from "src/utils/formatProdErrorMessage";
import compose from './compose';
import { Middleware, MiddlewareAPI } from './types/middleware';
import { StoreEnhancer, Dispatch } from './types/store';

/**
 * Creates a store enhancer that applies middleware to the dispatch method
 * of the Redux store. This is handy for a variety of tasks, such as expressing
 * asynchronous actions in a concise manner, or logging every action payload.
 *
 * See `redux-thunk` package as an example of the Redux middleware.
 *
 * Because middleware is potentially asynchronous, this should be the first
 * store enhancer in the composition chain.
 *
 * Note that each middleware will be given the `dispatch` and `getState` functions
 * as named arguments.
 *
 * @param middlewares The middleware chain to be applied.
 * @returns A store enhancer applying the middleware.
 *
 * @template Ext Dispatch signature added by a middleware.
 * @template S The type of the state supported by a middleware.
 */
export default function applyMiddleware(): StoreEnhancer;
export default function applyMiddleware<ext1, S="">(middleware1: Middleware<ext1, S,="" any="">): StoreEnhancer<{
  dispatch: Ext1;
}>;
export default function applyMiddleware<ext1, Ext2,="" S="">(middleware1: Middleware<ext1, S,="" any="">, middleware2: Middleware<ext2, S,="" any="">): StoreEnhancer<{
  dispatch: Ext1 & Ext2;
}>;
export default function applyMiddleware<ext1, Ext2,="" Ext3,="" S="">(middleware1: Middleware<ext1, S,="" any="">, middleware2: Middleware<ext2, S,="" any="">, middleware3: Middleware<ext3, S,="" any="">): StoreEnhancer<{
  dispatch: Ext1 & Ext2 & Ext3;
}>;
export default function applyMiddleware<ext1, Ext2,="" Ext3,="" Ext4,="" S="">(middleware1: Middleware<ext1, S,="" any="">, middleware2: Middleware<ext2, S,="" any="">, middleware3: Middleware<ext3, S,="" any="">, middleware4: Middleware<ext4, S,="" any="">): StoreEnhancer<{
  dispatch: Ext1 & Ext2 & Ext3 & Ext4;
}>;
export default function applyMiddleware<ext1, Ext2,="" Ext3,="" Ext4,="" Ext5,="" S="">(middleware1: Middleware<ext1, S,="" any="">, middleware2: Middleware<ext2, S,="" any="">, middleware3: Middleware<ext3, S,="" any="">, middleware4: Middleware<ext4, S,="" any="">, middleware5: Middleware<ext5, S,="" any="">): StoreEnhancer<{
  dispatch: Ext1 & Ext2 & Ext3 & Ext4 & Ext5;
}>;
export default function applyMiddleware<ext, S="any">(...middlewares: Middleware<any, S,="" any="">[]): StoreEnhancer<{
  dispatch: Ext;
}>;
export default function applyMiddleware(...middlewares: Middleware[]): StoreEnhancer<any> {
  return createStore => (reducer, preloadedState) => {
    const store = createStore(reducer, preloadedState);
    let dispatch: Dispatch = () => {
      throw new Error(process.env.NODE_ENV === "production" ? _formatProdErrorMessage(15) : 'Dispatching while constructing your middleware is not allowed. ' + 'Other middleware would not be applied to this dispatch.');
    };
    const middlewareAPI: MiddlewareAPI = {
      getState: store.getState,
      dispatch: (action, ...args) => dispatch(action, ...args)
    };
    const chain = middlewares.map(middleware => middleware(middlewareAPI));
    dispatch = compose<typeof dispatch="">(...chain)(store.dispatch);
    return {
      ...store,
      dispatch
    };
  };
}</typeof></any></any,></ext,></ext5,></ext4,></ext3,></ext2,></ext1,></ext1,></ext4,></ext3,></ext2,></ext1,></ext1,></ext3,></ext2,></ext1,></ext1,></ext2,></ext1,></ext1,></ext1,></ext1,>