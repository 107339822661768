import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RemoveIcon from '@material-ui/icons/Remove';

const ExpandButton = ({
  areAllRowsExpanded,
  buttonClass,
  expandableRowsHeader,
  expandedRows,
  iconClass,
  iconIndeterminateClass,
  isHeaderCell,
  onExpand,
}) => {
  return (
    <>
      {isHeaderCell && !areAllRowsExpanded() && areAllRowsExpanded && expandedRows.data.length > 0 ? (
        <iconbutton 0="" onClick="{onExpand}" style="{{" padding:="" }}="" disabled="{expandableRowsHeader" =="=" false}="" className="{buttonClass}">
          <removeicon id="expandable-button" className="{iconIndeterminateClass}"></removeicon>
        </iconbutton>
      ) : (
        <iconbutton 0="" onClick="{onExpand}" style="{{" padding:="" }}="" disabled="{expandableRowsHeader" =="=" false}="" className="{buttonClass}">
          <keyboardarrowrighticon id="expandable-button" className="{iconClass}"></keyboardarrowrighticon>
        </iconbutton>
      )}
    </>
  );
};

export default ExpandButton;
